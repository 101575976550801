import {Component, OnDestroy, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import {FormBuilder, Validators} from "@angular/forms";
import {selectBaselineAsset, selectGeneralInformationEdit, selectIsAnyAssetFormInEditMode, selectSelectedAsset} from "../../store/asset/asset.selectors";
import {map, Subscription} from "rxjs";
import {CodeTableEnum} from "../../../shared/model/code";
import {AssetGeneralInformationActions} from "../../store/asset.actions";
import {EMPTY_CODE, TraceableCode} from "../../../shared/model/traceable";
import {noWhitespaceValidator} from "../../../shared/utils/form-validators";
import {Asset} from "../../models/asset/asset";
import {EMPTY_ASSET} from "../../store/asset.reducer";

@Component({
    selector: "valumize-asset-general-information",
    templateUrl: "./asset-general-information.component.html"
})
export class AssetGeneralInformationComponent implements OnInit, OnDestroy {

    subscriptions: Subscription[] = [];

    asset$ = this.store.select(selectSelectedAsset);
    baselineAsset: Asset = EMPTY_ASSET;
    isEditDisabled$ = this.store.select(selectIsAnyAssetFormInEditMode);
    isEditable = false;
    isDataset = false;
    persistedRegions: TraceableCode[] = [];

    codeTableAssessment = CodeTableEnum.SHARED_ASSESSMENT;
    codeTableCountry = CodeTableEnum.SHARED_COUNTRY;
    codeTableCurrency = CodeTableEnum.SHARED_CURRENCY;
    codeTableESG = CodeTableEnum.ASSET_ESG;
    codeTableFiscalYearEnd = CodeTableEnum.SHARED_FISCALYEAREND;
    codeTableGpIndication = CodeTableEnum.ASSET_GPINDICATION;
    codeTableRegion = CodeTableEnum.SHARED_REGIONS;
    codeTableStage = CodeTableEnum.SHARED_STAGES;

    generalInformationForm = this.formBuilder.group({
        regions: this.formBuilder.control<string[]>([], {nonNullable: true}),
        country: this.formBuilder.control<string | null>(null),
        activityDescription: this.formBuilder.control<string>("", {validators: Validators.maxLength(100)}),
        stage: this.formBuilder.control<string>("", {nonNullable: true}),
        gic: this.formBuilder.control<string>("", {validators: Validators.maxLength(100)}),
        gpIndication: this.formBuilder.control<string>("", {nonNullable: true}),
        currency: this.formBuilder.control<string>("", {nonNullable: true, validators: noWhitespaceValidator()}),
        esg: this.formBuilder.control<string>("", {nonNullable: true}),
        assessment: this.formBuilder.control<string>("", {nonNullable: true}),
        fiscalYearEnd: this.formBuilder.control<string>("", {nonNullable: true}),
        publiclyListed: this.formBuilder.control<boolean>(false, {nonNullable: true}),
    });

    constructor(private readonly store: Store, private readonly formBuilder: FormBuilder) {
    }

    ngOnInit() {
        this.subscriptions.push(
            this.store.select(selectSelectedAsset).pipe(map((asset) => {
                    this.persistedRegions = asset.data.regions;
                    this.generalInformationForm.patchValue({
                        country: asset.data.country.code,
                        activityDescription: asset.data.activityDescription.text,
                        regions: asset.data.regions.map(r => r.code ?? ""),
                        stage: asset.data.stage.code,
                        gic: asset.data.gic.text,
                        gpIndication: asset.data.gpIndication.code,
                        currency: asset.data.currencyIso.code,
                        esg: asset.data.esg.code,
                        fiscalYearEnd: asset.data.fiscalYearEnd.code,
                        assessment: asset.data.assessment.code,
                        publiclyListed: asset.data.publiclyListed
                    });
                })
            ).subscribe());

        this.subscriptions.push(
            this.store.select(selectBaselineAsset).pipe(map((baselineAsset) => {
                if (baselineAsset.status === "LOADED") {
                    this.isDataset = true;
                    this.baselineAsset = baselineAsset.data;
                } else {
                    this.isDataset = false;
                }
            })).subscribe());

        this.subscriptions.push(
            this.store.select(selectGeneralInformationEdit).pipe(map((editable) => {
                this.isEditable = editable;
                if (editable) {
                    this.generalInformationForm.enable();
                } else {
                    this.generalInformationForm.disable();
                }
            })).subscribe());
    }

    save() {
        if (this.generalInformationForm.get("country")?.value?.toString().trim().length === 0) {
            this.generalInformationForm.get("country")?.setValue(null);
        }

        const updatedRegionsArray = this.updateRegionsArray();
        this.store.dispatch(AssetGeneralInformationActions.save({
            ...this.generalInformationForm.getRawValue(),
            regions: updatedRegionsArray
        }));
    }

    editMode = () => this.store.dispatch(AssetGeneralInformationActions.edit());

    cancel = () => {
        this.ngOnInit();
        this.store.dispatch(AssetGeneralInformationActions.cancel());
    };

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    private updateRegionsArray(): TraceableCode[] {
        return this.generalInformationForm.controls.regions.value
            .map(regionsFormValue => {
                const existingRegion = this.persistedRegions.find(region => region.code === regionsFormValue);
                return existingRegion || {...EMPTY_CODE, code: regionsFormValue};
            });
    }
}

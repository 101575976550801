import {Component, OnDestroy, OnInit, Renderer2} from "@angular/core";
import {Store} from "@ngrx/store";
import {selectDiscountMultipleValuation, selectSelectedAssetValuationDMV} from "../../../store/asset-valuation/asset-valuation.selectors";
import {AssetDiscountValuationActions} from "../../../store/asset.actions";
import {map, Subscription} from "rxjs";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AssetValuationScenario} from "../../../models/asset-valuation/asset-valuation-scenario";
import {AssetValuation} from "../../../models/asset-valuation/asset-valuation";
import {EMPTY_MULTIPLE, EMPTY_TRACEABLE, TraceableCode, TraceableMoneyWithCurrency, TraceableMultiple} from "../../../../shared/model/traceable";
import {AssetCashflow} from "../../../models/asset-valuation/asset-cashflow";
import {DecimalFormatPipe} from "../../../../shared/pipes/decimal-format/decimal-format.pipe";
import {numberNotBiggerThan190000m} from "../../../../shared/utils/form-validators";
import {EMPTY_ASSET_VALUATION} from "../../../store/asset.reducer";
import {DateUtil} from "../../../../shared/utils/date-util";
import {selectIsAnyAssetFormInEditMode} from "../../../store/asset/asset.selectors";
import {isValidValuationInput, ValuationInput} from "../../../models/asset-valuation/discount-multiple-valuation";
import {selectSelectedFundInvestmentNav, selectSelectedFundInvestmentTotalTVPI} from "../../../../fund/store/fund-investment/fund-investment.selectors";

@Component({
    selector: "valumize-asset-discount-multiple-valuation",
    templateUrl: "./asset-discount-multiple-valuation.component.html",
    styleUrls: ["./asset-discount-multiple-valuation.component.scss"],
})
export class AssetDiscountMultipleValuationComponent implements OnInit, OnDestroy {

    subscription?: Subscription;

    selectedValuationInput: ValuationInput = "MULTIPLE";

    selectedAssetValuation$ = this.store.select(selectSelectedAssetValuationDMV);
    discountMultipleValuation$ = this.store.select(selectDiscountMultipleValuation);
    fundInvestmentNav$ = this.store.select(selectSelectedFundInvestmentNav);
    requiredFieldsForAlgorithmLookup$ = this.store.select(selectSelectedFundInvestmentTotalTVPI);

    isEditDisabled$ = this.store.select(selectIsAnyAssetFormInEditMode);
    persistedScenarios: AssetValuationScenario[] = [];
    persistedAssetValuation: AssetValuation = EMPTY_ASSET_VALUATION;
    calculatedScenarios: AssetValuationScenario[] = [];
    calculatedAssetValuation?: AssetValuation;

    fundCurrency?: TraceableCode;
    isEditable = false;
    rationaleIsEditable = false;

    constructor(
        private readonly store: Store,
        private readonly formBuilder: FormBuilder,
        private readonly renderer: Renderer2
    ) {
    }

    discountMultipleValuationForm = this.formBuilder.group({
        mostRecentCashflowDate: this.formBuilder.control<Date | null>(null, {
            nonNullable: true,
            validators: [Validators.required]
        }),
        calculatedRequiredGrossIRR: this.formBuilder.control<number | null>(null, {
            nonNullable: true,
            validators: [Validators.required, numberNotBiggerThan190000m()]
        }),
        lowCalc: this.formBuilder.control<number | null>(null, {
            nonNullable: true,
            validators: [Validators.required, numberNotBiggerThan190000m()]
        }),
        baseCalc: this.formBuilder.control<number | null>(null, {
            nonNullable: true,
            validators: [Validators.required, numberNotBiggerThan190000m()]
        }),
        highCalc: this.formBuilder.control<number | null>(null, {
            nonNullable: true,
            validators: [Validators.required, numberNotBiggerThan190000m()]
        })
    });

    nextFocus: string | undefined;
    controlNamesForFocus = ["calculatedRequiredGrossIRR", "valuationInputToggle", "mostRecentCashflowDate", "lowCalc", "baseCalc", "highCalc"];

    ngOnInit() {
        this.subscription = this.store.select(selectDiscountMultipleValuation).pipe(map((discountMultipleValuation) => {
            this.isEditable = discountMultipleValuation.discountMultipleValuationEditable;
            this.rationaleIsEditable = discountMultipleValuation.rationaleIsEditable;

            this.persistedAssetValuation = discountMultipleValuation.persistedAssetValuation;
            this.persistedScenarios = discountMultipleValuation.persistedScenarios;
            this.calculatedAssetValuation = discountMultipleValuation.calcAssetValuation;
            this.calculatedScenarios = discountMultipleValuation.calcScenarios;
            this.fundCurrency = discountMultipleValuation.fundCurrency;

            const rowValue = this.isCashflowValuation()
                ? discountMultipleValuation.tableDataSource.find(row => row.definition === "cashflowAmount")
                : discountMultipleValuation.tableDataSource.find(row => row.definition === "navMultiple");

            if (rowValue) {
                if (this.isCashflowValuation()) {
                    this.discountMultipleValuationForm.patchValue({
                        lowCalc: DecimalFormatPipe.transformFromMillionsNum((rowValue.lowCalc as TraceableMoneyWithCurrency)?.amount ?? undefined),
                        baseCalc: DecimalFormatPipe.transformFromMillionsNum((rowValue.baseCalc as TraceableMoneyWithCurrency)?.amount ?? undefined),
                        highCalc: DecimalFormatPipe.transformFromMillionsNum((rowValue.highCalc as TraceableMoneyWithCurrency)?.amount ?? undefined)
                    });
                } else {
                    this.discountMultipleValuationForm.patchValue({
                        lowCalc: (rowValue.lowCalc as TraceableMultiple)?.factor,
                        baseCalc: (rowValue.baseCalc as TraceableMultiple)?.factor,
                        highCalc: (rowValue.highCalc as TraceableMultiple)?.factor
                    });
                }
            }

            this.discountMultipleValuationForm.patchValue({
                calculatedRequiredGrossIRR: DecimalFormatPipe.transformFractionToPercent(discountMultipleValuation.calcAssetValuation.requiredGrossIRR.fraction ?? 0.25),
                mostRecentCashflowDate: discountMultipleValuation.mostRecentCalcCashflowDate
            });

            if (this.isEditable) {
                // Use a setTimeout to refocus on the next Javascript event loop tick,
                // giving Angular a chance to update the DOM first
                setTimeout(() => {
                    if (this.nextFocus) {
                        this.renderer.selectRootElement("#" + this.nextFocus).focus();
                    }
                }, 0);
                this.discountMultipleValuationForm.enable();
            } else {
                this.discountMultipleValuationForm.disable();
            }
        })).subscribe();
    }

    onValueChange = (valuationInput: string) => {
        if (isValidValuationInput(valuationInput)) {
            this.selectedValuationInput = valuationInput;
            this.ngOnInit();
        }
    };

    isCashflowValuation = () => this.selectedValuationInput === "CF";

    navExists = (nav?: number | null) => nav !== undefined && nav !== null && nav !== 0;

    requiredFieldsExist = (requiredFields: { totalTVPI: number | undefined; investmentDate: string | undefined }) =>
        requiredFields.totalTVPI !== undefined && requiredFields.totalTVPI !== null && requiredFields.totalTVPI !== 0
        && requiredFields.investmentDate !== undefined && requiredFields.investmentDate !== null && requiredFields.investmentDate.trim().length !== 0;

    tootTipMessage = (requiredFields: { totalTVPI: number | undefined; investmentDate: string | undefined }): string => {
        const missingFields: string[] = [];

        if (requiredFields.totalTVPI === undefined || requiredFields.totalTVPI === null || requiredFields.totalTVPI === 0) {
            missingFields.push("Total TVPI");
        }
        if (requiredFields.investmentDate === undefined || requiredFields.investmentDate === null || requiredFields.investmentDate.trim().length === 0) {
            missingFields.push("Investment Date");
        }

        return missingFields.length === 0 ? "" : `Missing fields: ${missingFields.join(", ")}`;
    };

    calcAndSave(focus: string, save = false, withAlgorithm = false) {
        if (focus.length > 0) {
            const currentIndex = this.controlNamesForFocus.findIndex(control => control === focus);
            this.nextFocus = this.controlNamesForFocus[currentIndex + 1] || this.controlNamesForFocus[0];
        }

        const updatedAssetValuation = this.assetValuationForCalc();
        const updatedScenarios = this.scenarioForCalc(this.persistedScenarios, this.discountMultipleValuationForm);

        if (withAlgorithm || (!withAlgorithm && this.discountMultipleValuationForm.valid)) {
            this.store.dispatch(
                AssetDiscountValuationActions.calc({
                    assetValuation: updatedAssetValuation,
                    scenarios: updatedScenarios,
                    save,
                    valuationInput: this.selectedValuationInput,
                    withAlgorithm
                })
            );
        }
    }

    assetValuationForCalc(): AssetValuation {
        const mostRecentCashflowDate =
            DateUtil.toIsoDate(this.discountMultipleValuationForm.controls.mostRecentCashflowDate.value)
            ?? this.persistedAssetValuation.exitDateAssumption.date;

        const requiredGrossIRR =
            DecimalFormatPipe.transformPercentToFraction(this.discountMultipleValuationForm.controls.calculatedRequiredGrossIRR.value)
            ?? this.persistedAssetValuation.requiredGrossIRR.fraction;

        return {
            ...this.persistedAssetValuation,
            requiredGrossIRR: {
                ...this.persistedAssetValuation.requiredGrossIRR,
                fraction: requiredGrossIRR
            },
            exitDateAssumption: {
                ...this.persistedAssetValuation.exitDateAssumption,
                date: mostRecentCashflowDate
            }
        };
    }

    scenarioForCalc(
        oldScenarios: AssetValuationScenario[],
        form: FormGroup<{
            mostRecentCashflowDate: FormControl<Date | null>;
            calculatedRequiredGrossIRR: FormControl<number | null>;
            lowCalc: FormControl<number | null>;
            baseCalc: FormControl<number | null>;
            highCalc: FormControl<number | null>;
        }>
    ): AssetValuationScenario[] {
        const lowScenario = oldScenarios.find(s => s.scenario.code === "LOW");
        const baseScenario = oldScenarios.find(s => s.scenario.code === "BASE");
        const highScenario = oldScenarios.find(s => s.scenario.code === "HIGH");

        const updatedFormValues = form.getRawValue();
        const updatedScenarios: AssetValuationScenario[] = [];

        const lowScenarioValue = this.isCashflowValuation() ? DecimalFormatPipe.transformToMillionsNum(updatedFormValues.lowCalc) : updatedFormValues.lowCalc;
        const baseScenarioValue = this.isCashflowValuation() ? DecimalFormatPipe.transformToMillionsNum(updatedFormValues.baseCalc) : updatedFormValues.baseCalc;
        const highScenarioValue = this.isCashflowValuation() ? DecimalFormatPipe.transformToMillionsNum(updatedFormValues.highCalc) : updatedFormValues.highCalc;

        if (lowScenario) {
            const updatedLowScenario = this.createScenario(lowScenario, lowScenarioValue ?? undefined);
            updatedScenarios.push(updatedLowScenario);
        }
        if (baseScenario) {
            const updatedBaseScenario = this.createScenario(baseScenario, baseScenarioValue ?? undefined);
            updatedScenarios.push(updatedBaseScenario);
        }
        if (highScenario) {
            const updatedHighScenario = this.createScenario(highScenario, highScenarioValue ?? undefined);
            updatedScenarios.push(updatedHighScenario);
        }
        return updatedScenarios;
    }

    private createScenario(scenario: AssetValuationScenario, value?: number): AssetValuationScenario {
        return this.isCashflowValuation()
            ? {
                ...scenario,
                cashflows: [{
                    id: scenario.cashflows.at(0)?.id ?? undefined,
                    date: {...EMPTY_TRACEABLE, date: DateUtil.toIsoDate(this.discountMultipleValuationForm.controls.mostRecentCashflowDate.value)},
                    cashflowType: {...EMPTY_TRACEABLE, code: "DISTRIBUTION"},
                    amount: {...EMPTY_TRACEABLE, currencyIso: this.fundCurrency?.code ?? "XXX", amount: value}, // Code XXX is used to denote a "transaction" with no currency
                    navMultiple: scenario.navMultiple ?? EMPTY_MULTIPLE
                } as AssetCashflow]
            }
            : {
                ...scenario,
                navMultiple: {
                    ...scenario.navMultiple,
                    factor: value
                }
            };
    }

    editMode = () => this.store.dispatch(AssetDiscountValuationActions.edit());

    cancel = () => this.store.dispatch(AssetDiscountValuationActions.cancel());

    formControlIsEditable(name: string): boolean {
        const editableRows = this.isCashflowValuation() ? ["cashflowAmount"] : ["navMultiple"];
        return editableRows.includes(name) && this.isEditable;
    }

    styleRequiredFormFieldWithAsterisk(name: string): string {
        return name === "cashflowAmount" ? "required-asterisk" : "";
    }

    styleRowImportant(row: any): string {
        const importantRowTypes = ["cashflowAmount"];
        return (importantRowTypes.includes(row.definition)) ? "row-important" : "";
    }

    styleRowBorderTop(row: any): string {
        const borderTopRowTypes = ["navMultiple"];
        return (borderTopRowTypes.includes(row.definition)) ? "row-top-border" : "";
    }

    onEditRationale = () => this.store.dispatch(AssetDiscountValuationActions.editrationale());

    onCancelRationale = () => this.store.dispatch(AssetDiscountValuationActions.cancelrationale());

    onSaveRationale = (assetValuationToSave: AssetValuation) => this.store.dispatch(AssetDiscountValuationActions.saverationale({assetValuation: assetValuationToSave}));

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
}

/* eslint-disable arrow-body-style */
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {Store} from "@ngrx/store";
import {catchError, exhaustMap, map, of, withLatestFrom} from "rxjs";
import {SellerPositionService} from "../../services/seller-position.service";
import {DealActions, SellerPositionActions} from "../deal.actions";
import {SellerPosition} from "../../models/seller-position";
import {selectSelectedDealId} from "../deal/deal.selectors";
import {selectSelectedFundValuationId} from "../../../fund/store/fund-valuation/fund-valuation.selectors";
import {selectSelectedSourceDataset} from "../../../import/store/import.selectors";
import {Router} from "@angular/router";
import {tap} from "rxjs/operators";

export const loadAllSellerPositions = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), sellerPositionService = inject(SellerPositionService)) => {
        return actions$.pipe(
            ofType(SellerPositionActions.loadall),
            exhaustMap((action) =>
                sellerPositionService.getSellerPositionsForDealAndFundValuation(action.dealId).pipe(
                    map((sellerPositions) => SellerPositionActions.loadallsuccess({sellerPositions})),
                    catchError((error: { message: string }) => of(SellerPositionActions.loaderror({errorMsg: error.message})))
                )
            )
        );
    },
    {functional: true}
);

export const saveSellerPositions = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), sellerPositionService = inject(SellerPositionService)) => {
        return actions$.pipe(
            ofType(SellerPositionActions.saveall),
            exhaustMap((action) => {
                if (action.dealId) {
                    return sellerPositionService.updateSellerPositions(action.dealId, action.sellerPositions).pipe(
                        map((sellerPositions) => SellerPositionActions.loadallsuccess({sellerPositions})),
                        catchError((error: { message: string }) => of(SellerPositionActions.loaderror({errorMsg: error.message})))
                    );
                } else {
                    return of(SellerPositionActions.loaderror({errorMsg: "Deal could not be found"}));
                }
            })
        );
    },
    {functional: true}
);

export const loadDealAfterSaveSellerPositions = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(SellerPositionActions.loadallsuccess),
            withLatestFrom(store$.select(selectSelectedDealId)),
            map(([, dealId]) => dealId
                ? DealActions.load({dealId})
                : DealActions.loaderror({errorMsg: "Error while loading the deal, please refresh the page"}))
        );
    },
    {functional: true}
);

export const loadSellerPositionsForDealAndFundValuation = createEffect(
    (actions$ = inject(Actions), sellerPositionService = inject(SellerPositionService)) => {
        return actions$.pipe(
            ofType(SellerPositionActions.load),
            exhaustMap((action) => {
                return sellerPositionService.getSellerPositionsForDealAndFundValuation(action.dealId, action.fundValuationId).pipe(
                    map((sellerPositions) => sellerPositions.length !== 0
                        ? SellerPositionActions.loadsuccess({sellerPosition: sellerPositions.at(0) as SellerPosition})
                        : SellerPositionActions.loaderror({errorMsg: `No seller position for this deal and fund valuation available`})
                    ),
                    catchError((error: { message: string }) => of(SellerPositionActions.loaderror({errorMsg: error.message})))
                );
            })
        );
    },
    {functional: true}
);

export const calcAfterLoadingSellerPosition = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(SellerPositionActions.loadsuccess),
            withLatestFrom(store$.select(selectSelectedDealId), store$.select(selectSelectedFundValuationId)),
            map(([action, dealId, fundValuationId]) => dealId && fundValuationId
                ? SellerPositionActions.calc({dealId, fundValuationId, sellerPosition: action.sellerPosition})
                : SellerPositionActions.calcerror({errorMsg: "Error while calculating the seller position, please refresh the page"}))
        );
    },
    {functional: true}
);

export const saveSellerPosition = createEffect(
    (actions$ = inject(Actions), sellerPositionService = inject(SellerPositionService)) => {
        return actions$.pipe(
            ofType(SellerPositionActions.save),
            exhaustMap((action) => {
                if (!!action.dealId && !!action.fundValuationId) {
                    return sellerPositionService.save(action.dealId, action.fundValuationId, action.sellerPosition).pipe(
                        map((sellerPosition) => SellerPositionActions.saveloadsuccess({sellerPosition})),
                        catchError((error: { message: string }) => of(SellerPositionActions.loaderror({errorMsg: error.message})))
                    );
                } else {
                    return of(SellerPositionActions.loaderror({errorMsg: "Error while loading deal and/or fund valuation, please try again"}));
                }
            })
        );
    },
    {functional: true}
);

export const calcAfterSavingSellerPosition = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(SellerPositionActions.saveloadsuccess),
            withLatestFrom(store$.select(selectSelectedDealId), store$.select(selectSelectedFundValuationId)),
            map(([action, dealId, fundValuationId]) => dealId && fundValuationId
                ? SellerPositionActions.calc({dealId, fundValuationId, sellerPosition: action.sellerPosition})
                : SellerPositionActions.calcerror({errorMsg: "Error while calculating the seller position, please refresh the page"}))
        );
    },
    {functional: true}
);

export const calcSellerPosition = createEffect(
    (actions$ = inject(Actions), sellerPositionService = inject(SellerPositionService)) => {
        return actions$.pipe(
            ofType(SellerPositionActions.calc),
            exhaustMap((action) => {
                if (!!action.dealId && !!action.fundValuationId && !!action.sellerPosition.id) {
                    return sellerPositionService.calculateSellerPosition(action.dealId, action.fundValuationId, action.sellerPosition.id, action.sellerPosition).pipe(
                        map((sellerPositionCalc) => SellerPositionActions.calcsuccess({sellerPositionCalc})),
                        catchError((error: { message: string }) => of(SellerPositionActions.calcerror({errorMsg: error.message})))
                    );
                } else {
                    return of(SellerPositionActions.loaderror({errorMsg: "No seller position for this deal and fund valuation available"}));
                }
            })
        );
    },
    {functional: true}
);

export const loadSellerPositionInDataset = createEffect(
    (actions$ = inject(Actions), store$ = inject(Store), sellerPositionService = inject(SellerPositionService)) => {
        return actions$.pipe(
            ofType(SellerPositionActions.loaddataset),
            withLatestFrom(store$.select(selectSelectedSourceDataset)),
            exhaustMap(([action, dataset]) => {
                if (dataset !== "master") {
                    return sellerPositionService.getSellerPositionForFundValuation(action.fundValuationId, dataset).pipe(
                        map((sellerPositionAndDealId) => SellerPositionActions.loaddatasetsuccess({
                            sellerPosition: sellerPositionAndDealId.sellerPosition,
                            dealId: sellerPositionAndDealId.dealId
                        })),
                        catchError((error: { message: string }) => of(SellerPositionActions.loaderror({errorMsg: error.message}))));
                } else {
                    return of(SellerPositionActions.loaddatasetdonothing());
                }
            })
        );
    },
    {functional: true}
);

export const navigateOnSuccess = createEffect(
    (actions$ = inject(Actions), router$ = inject(Router)) => {
        return actions$.pipe(
            ofType(SellerPositionActions.loaddatasetsuccess),
            tap((action) => router$.navigate([], {queryParams: {dealId: action.dealId}, queryParamsHandling: "merge"})));
    },
    {functional: true, dispatch: false}
);


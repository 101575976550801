import {Injectable} from "@angular/core";
import {map, Observable} from "rxjs";
import {AssetValuation} from "../models/asset-valuation/asset-valuation";
import {AssetApiService} from "../generated-sources/api";
import {AssetValuationScenario} from "../models/asset-valuation/asset-valuation-scenario";
import {DiscountMultipleValuation} from "../models/asset-valuation/discount-multiple-valuation";
import {FinancialPerformanceRecord} from "../models/asset/financial-performance-record";
import {FinancialOperationalValuation} from "../models/asset-valuation/financial-operational-valuation";

@Injectable({
    providedIn: "root"
})
export class AssetValuationService {

    constructor(private readonly assetApiService: AssetApiService) {
    }

    public getValuationsForAsset(assetId: number): Observable<AssetValuation[]> {
        return this.assetApiService.getValuationsForAsset(assetId);
    }

    public getAssetValuation(assetId: number, assetValuationId: number): Observable<AssetValuation> {
        return this.assetApiService.getAssetValuation(assetId, assetValuationId);
    }

    public save(assetId: number, valuation: AssetValuation): Observable<AssetValuation> {
        const result: Observable<AssetValuation> = !!valuation.id && assetId
            ? this.assetApiService.updateAssetValuation(assetId, valuation.id, valuation)
            : this.assetApiService.createAssetValuation(assetId, valuation);
        return result.pipe(map((valuationDto: AssetValuation) => valuationDto));
    }

    public delete(assetId: number, assetValuationId: number): Observable<number> {
        return this.assetApiService.deleteAssetValuation(assetId, assetValuationId);
    }

    public getScenariosForAssetValuation(assetId: number, assetValuationId: number): Observable<AssetValuationScenario[]> {
        return this.assetApiService.getScenariosForAssetValuation(assetId, assetValuationId);
    }

    public getAssetValuationScenario(assetId: number, assetValuationId: number, scenarioId: number) {
        return this.assetApiService.getAssetValuationScenario(assetId, assetValuationId, scenarioId);
    }

    public saveScenario(assetId: number, assetValuationId: number, scenario: AssetValuationScenario) {
        const result: Observable<AssetValuationScenario> = !!scenario.id && !!assetId && !!assetValuationId
            ? this.assetApiService.updateAssetValuationScenario(assetId, assetValuationId, scenario.id, scenario)
            : this.assetApiService.createAssetValuationScenario(assetId, assetValuationId, scenario);
        return result.pipe(map((scenarioDto: AssetValuationScenario) => scenarioDto));
    }

    public saveScenariosForAssetValuation(assetId: number, assetValuationId: number, scenarios: AssetValuationScenario[]): Observable<AssetValuationScenario[]> {
        return this.assetApiService.updateScenariosForAssetValuation(assetId, assetValuationId, scenarios);
    }

    public calcDiscountMultipleValuation(assetId: number, assetValuationId: number, dmv: DiscountMultipleValuation, withAlgorithm: boolean): Observable<DiscountMultipleValuation> {
        return this.assetApiService.calculateDiscountMultipleValuation(assetId, assetValuationId, withAlgorithm, dmv);
    }

    public calcOperationalValuation(assetId: number, assetValuationId: number, operationalValuation: FinancialOperationalValuation): Observable<FinancialOperationalValuation> {
        return this.assetApiService.calculateFinancialOperationalValuation(assetId, assetValuationId, operationalValuation);
    }

    public getFinancialPerformanceForValuation(assetId: number, assetValuationId: number): Observable<FinancialPerformanceRecord[]> {
        return this.assetApiService.getFinancialPerformanceForValuation(assetId, assetValuationId);
    }

    public getFinancialPerformanceForScenario(assetId: number, assetValuationId: number, scenarioId: number): Observable<FinancialPerformanceRecord[]> {
        return this.assetApiService.getFinancialPerformanceForScenario(assetId, assetValuationId, scenarioId);
    }

    public saveFinancialPerformanceForScenario(assetId: number, assetValuationId: number, scenarioId: number, financialPerformanceRecords: FinancialPerformanceRecord[]) {
        return this.assetApiService.updateFinancialPerformanceForScenario(assetId, assetValuationId, scenarioId, financialPerformanceRecords);
    }

    public calculateFinancialPerformanceForScenario(assetId: number, assetValuationId: number, scenarioId: number, financialPerformanceRecords: FinancialPerformanceRecord[]) {
        return this.assetApiService.calculateFinancialPerformanceForScenario(assetId, assetValuationId, scenarioId, financialPerformanceRecords);
    }
}

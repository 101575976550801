import {Injectable} from "@angular/core";
import {CodeApiService} from "../../generated-sources/api/i18n";
import {Observable} from "rxjs";
import {Code} from "../../model/code";

@Injectable({
    providedIn: "root"
})
export class CodeService {

    constructor(private readonly codeApiService: CodeApiService) {
    }

    getCodeTables(findByTable: string): Observable<Code[]> {
        return this.codeApiService.search(findByTable);
    }
}

import {createFeatureSelector, createSelector} from "@ngrx/store";
import {FundState} from "../fund.state";
import {fundStoreFeatureKey} from "../fund.reducer";

export const selectFundState = createFeatureSelector<FundState>(fundStoreFeatureKey);

export const selectGeneralPartners = createSelector(
    selectFundState,
    (state) => state.generalPartners.data
);

export const selectSelectedGeneralPartner = createSelector(
    selectFundState,
    (state) => state.selectedGeneralPartner.data
);

export const selectSelectedGeneralPartnerId = createSelector(
    selectSelectedGeneralPartner,
    (state) => state.id
);

import {Component, OnInit} from "@angular/core";
import {combineLatest, map, Observable, startWith} from "rxjs";
import {FormBuilder, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {DateTime} from "luxon";
import {DealActions} from "../store/deal.actions";
import {DateUtil} from "../../shared/utils/date-util";
import {selectCodesFromTable} from "../../shared/store/shared.selectors";
import {Code, CodeTableEnum} from "../../shared/model/code";
import {noWhitespaceValidator} from "../../shared/utils/form-validators";

@Component({
    selector: "valumize-deal-new-dialog",
    templateUrl: "./deal-new-dialog.component.html",
    styleUrls: ["./deal-new-dialog.component.scss"]
})
export class DealNewDialogComponent implements OnInit {

    codeTableCurrency = CodeTableEnum.SHARED_CURRENCY;

    selectCodesCurrency$ = this.store.select(selectCodesFromTable(this.codeTableCurrency));
    filteredCurrencies$: Observable<Code[]> | undefined;

    dealForm = this.formBuilder.group({
        dealName: this.formBuilder.control<string>("", {nonNullable: true, validators: [Validators.required, Validators.maxLength(100), noWhitespaceValidator()]}),
        closingDate: this.formBuilder.control<Date>(DateTime.now().toJSDate(), {nonNullable: true, validators: [Validators.required, noWhitespaceValidator()]}),
        currencyIso: this.formBuilder.control<string>("", {nonNullable: true, validators: [Validators.required, noWhitespaceValidator()]})
    });

    constructor(private readonly store: Store,
                private readonly formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.filteredCurrencies$ = combineLatest([
            this.dealForm.controls.currencyIso.valueChanges.pipe(startWith("")),
            this.selectCodesCurrency$
        ]).pipe(
            map(([value, currencies]) => value
                ? currencies.filter(currency => currency.translations?.["en"].toLowerCase().includes(value.toLowerCase()))
                : currencies
            )
        );
    }

    createDeal() {
        if (this.dealForm.valid) {
            const updatedFormValues = this.dealForm.getRawValue();
            this.store.dispatch(DealActions.create({
                dealName: updatedFormValues.dealName,
                closingDate: DateUtil.toIsoDate(updatedFormValues.closingDate) as string,
                currencyIso: updatedFormValues.currencyIso
            }));
        }
    }
}

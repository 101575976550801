/* eslint-disable arrow-body-style */
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {catchError, exhaustMap, map, of} from "rxjs";
import {Store} from "@ngrx/store";
import {GeneralPartnerService} from "../../services/general-partner.service";
import {FundActions, GeneralPartnerActions, GeneralPartnerListActions} from "../fund.actions";
import {EMPTY_GENERAL_PARTNER} from "../fund.reducer";

export const loadGeneralPartnerList = createEffect(
    (actions$ = inject(Actions), generalPartnerService = inject(GeneralPartnerService)) => {
        return actions$.pipe(
            ofType(GeneralPartnerListActions.load),
            exhaustMap(() =>
                generalPartnerService.getGeneralPartners().pipe(
                    map((generalPartners) => GeneralPartnerListActions.loadsuccess({generalPartners})),
                    catchError((error: { message: string }) => of(GeneralPartnerListActions.loaderror({errorMsg: error.message}))))
            )
        );
    },
    {functional: true}
);

export const loadGeneralPartner = createEffect(
    (actions$ = inject(Actions), generalPartnerService = inject(GeneralPartnerService)) => {
        return actions$.pipe(
            ofType(GeneralPartnerActions.load),
            exhaustMap((action) => {
                return generalPartnerService.getGeneralPartner(action.generalPartnerId).pipe(
                    map((generalPartner) => GeneralPartnerActions.loadsuccess({generalPartner})),
                    catchError((error: { message: string }) => of(GeneralPartnerActions.loaderror({errorMsg: error.message}))));
            })
        );
    },
    {functional: true}
);

export const createGeneralPartner = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), generalPartnerService = inject(GeneralPartnerService)) => {
        return actions$.pipe(
            ofType(GeneralPartnerActions.create),
            exhaustMap((action) => {
                return generalPartnerService.saveGeneralPartner({
                        ...EMPTY_GENERAL_PARTNER,
                        ...{
                            name: {
                                ...EMPTY_GENERAL_PARTNER.name,
                                text: action.name
                            },
                            quality: {
                                ...EMPTY_GENERAL_PARTNER.quality,
                                code: action.quality
                            }
                        }
                    }
                ).pipe(
                    map((generalPartner) => GeneralPartnerActions.loadsuccess({generalPartner})),
                    catchError((error: { message: string }) => of(GeneralPartnerActions.loaderror({errorMsg: error.message}))));
            })
        );
    },
    {functional: true}
);

export const saveGeneralPartnerOnFundAfterLoading = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(GeneralPartnerActions.loadsuccess),
            map((action) => action.generalPartner.id
                ? FundActions.savegeneralpartneronfund({generalPartnerId: action.generalPartner.id})
                : GeneralPartnerActions.loaderror({errorMsg: "Error while loading the General Partner, please refresh the page."})));
    },
    {functional: true}
);

<mat-sidenav-container>
    <mat-sidenav #sidenav class="mat-elevation-z8" mode="side" opened>
        <div *ngIf="sidenav.opened" class="top-right-nav-toggle">
            <button (click)="sidenav.toggle()" color="primary" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div>
            <div class="sidenav-info">
                <ng-container *ngIf="reportDate$ | async as reportDate" class="report-date">
                    <ng-container *ngIf="!!reportDate.date; else selectOrCreateFundReport">
                        <div>
                            <div style="display: flex">
                                <div>
                                    <label data-test="report-date-label">Report Date</label>
                                    <p [class.highlight]="highlightFundReportDate" data-test="report-date">{{reportDate | traceableFormat}}</p>
                                </div>
                                <div>
                                    <button
                                        (click)="openNewFundReportDialog()"
                                        color="primary"
                                        data-test="edit-report-date-button"
                                        mat-icon-button>
                                        <mat-icon>manage_history</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <ng-container *ngIf="(sourceDataset$ | async) === 'master'">
                                <ng-container *ngIf="closingDate$ | async as closingDate">
                                    <ng-container *ngIf="!!closingDate.date; else noClosingDate">
                                        <div style="display: flex">
                                            <div>
                                                <label data-test="closing-date-label">Closing Date</label>
                                                <p [class.highlight]="highlightFundClosingDate" data-test="closing-date">{{closingDate | traceableFormat}}</p>
                                            </div>
                                            <div>
                                                <button
                                                    (click)="openNewFundValuationDialog()"
                                                    color="primary"
                                                    data-test="edit-report-date-button"
                                                    mat-icon-button>
                                                    <mat-icon>manage_history</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <ng-template #noClosingDate>
                                    <button (click)="openNewFundValuationDialog()"
                                            color="primary"
                                            data-test="create-or-select-fund-valuation"
                                            mat-stroked-button>
                                        <mat-icon>add</mat-icon>
                                        Fund Valuation
                                    </button>
                                </ng-template>
                            </ng-container>
                            <ng-container *ngIf="selectedGeneralPartner$ | async as generalPartner">
                                <ng-container *ngIf="!!generalPartner.id; else noGeneralPartner">
                                    <div style="display: flex">
                                        <div>
                                            <label data-test="general-partner-label">General Partner</label>
                                            <p data-test="general-partner">{{generalPartner.name | traceableFormat}}</p>
                                        </div>
                                        <div>
                                            <button
                                                (click)="openNewGeneralPartnerDialog()"
                                                color="primary"
                                                data-test="edit-general-partner-button"
                                                mat-icon-button>
                                                <mat-icon>manage_history</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-template #noGeneralPartner>
                                    <button (click)="openNewGeneralPartnerDialog()"
                                            class="add-general-partner-button"
                                            color="primary"
                                            data-test="create-or-select-general-partner"
                                            mat-stroked-button>
                                        <mat-icon>add</mat-icon>
                                        General Partner
                                    </button>
                                </ng-template>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-template #selectOrCreateFundReport>
                        <button (click)="openNewFundReportDialog()"
                                color="primary"
                                data-test="create-or-select-fund-report"
                                mat-stroked-button>
                            <mat-icon>add</mat-icon>
                            Fund Report
                        </button>
                    </ng-template>
                </ng-container>
            </div>
            <mat-divider></mat-divider>
            <ng-container *ngIf="(sourceDataset$ | async) !== 'master'">
                <div class="sidenav-info">
                    <h3>Merge Targets</h3>
                </div>
                <div class="sidenav-info">
                    <div>
                        <div class="label-search">
                            <label>Selected Fund</label>
                            <button (click)="displaySearch()"
                                    color="primary"
                                    data-test="search-merge-target-button"
                                    mat-icon-button>
                                <mat-icon>search</mat-icon>
                            </button>
                        </div>
                        <ng-container *ngIf="searchEnabled">
                            <valumize-search
                                (searchResultSelected)="selectFundMergeTarget($event)"
                                [searchCategories]="['funds']"
                                [showSearchIcon]="false"
                                [targetSearch]="true">
                            </valumize-search>
                        </ng-container>
                        <ng-container *ngIf="baselineFund$ | async as baselineFund">
                            <div class="selected-fund">
                                <p class="selected-fund-name">{{baselineFund.data.name | traceableFormat}}</p>
                                <ng-container *ngIf="baselineFund.data.id">
                                    <button (click)="deselectFundMergeTarget()"
                                            color="primary"
                                            data-test="cancel-button"
                                            mat-icon-button>
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>

                </div>
                <ng-container *ngIf="(baselineFund$ | async) as baselineFund">
                    <ng-container *ngIf="baselineFund.data.id">
                        <div class="sidenav-info">
                            <div>
                                <label>Selected Fund Report</label>
                                <ng-container *ngIf="baselineFundReport$ | async as baselineFundReport">
                                    <p>{{baselineFundReport.data.reportDate | traceableFormat}}</p>
                                </ng-container>
                            </div>
                            <ng-container *ngIf="fundReport$ | async as fundReport">
                                <div *ngIf="!fundReport.fundReport.data.mergeTargetId; else loadFundReport">
                                    <button (click)="selectFundReportMergeTarget(baselineFund.data.id)"
                                            color="primary"
                                            data-test="select-merge-target-button"
                                            mat-stroked-button>
                                        Select
                                    </button>
                                </div>
                                <ng-template #loadFundReport>
                                    <ng-container *ngIf="baselineFundReport$ | async as baselineFundReport">
                                        <div *ngIf="!baselineFundReport.data.id">
                                            <button (click)="loadFundReportMergeTarget(baselineFund.data.id, fundReport.fundReport.data.mergeTargetId)"
                                                    color="warn"
                                                    data-test="select-merge-target-button"
                                                    mat-stroked-button>
                                                Load
                                            </button>
                                        </div>
                                        <ng-container *ngIf="baselineFundReport.data.id">
                                            <button (click)="deselectFundReportMergeTarget()"
                                                    color="primary"
                                                    data-test="cancel-button"
                                                    mat-icon-button>
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </ng-container>
                                    </ng-container>
                                </ng-template>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
                <mat-divider></mat-divider>
            </ng-container>
            <mat-action-list>
                <a (click)="scrollToComponent('partnership-investments')" data-test="partnership-investments-nav-item">
                    <mat-list-item>Partnership Investments</mat-list-item>
                </a>
                <ng-container *ngIf="(sourceDataset$ | async) === 'master'">
                    <a (click)="scrollToComponent('treemap-actual')" data-test="treemap-actual-nav-item">
                        <mat-list-item>Treemap Actual</mat-list-item>
                    </a>
                </ng-container>
                <a (click)="scrollToComponent('fund-details')" data-test="fund-details-nav-item">
                    <mat-list-item>Fund Details</mat-list-item>
                </a>
                <a (click)="scrollToComponent('fund-report-details')" data-test="fund-report-details-nav-item">
                    <mat-list-item>Fund Report Details</mat-list-item>
                </a>
                <a (click)="scrollToComponent('seller-position')" data-test="seller-position-nav-item">
                    <mat-list-item>Seller Position</mat-list-item>
                </a>
                <ng-container *ngIf="(sourceDataset$ | async) === 'master'">
                    <a (click)="scrollToComponent('undrawn-valuation')" data-test="undrawn-valuation-nav-item">
                        <mat-list-item>Undrawn Valuation</mat-list-item>
                    </a>
                    <a (click)="scrollToComponent('return-summary')" data-test="return-summary-nav-item">
                        <mat-list-item>Return Summary</mat-list-item>
                    </a>
                    <a (click)="scrollToComponent('fund-cashflows')" data-test="fund-cashflows-nav-item">
                        <mat-list-item>Fund Cashflows</mat-list-item>
                    </a>
                </ng-container>
            </mat-action-list>
            <mat-divider></mat-divider>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <div *ngIf="!sidenav.opened" class="top-left-nav-toggle">
            <button (click)="sidenav.toggle()" color="primary" mat-icon-button>
                <mat-icon>menu</mat-icon>
            </button>
        </div>
        <ng-container *ngIf="fundName$ | async as fundName">
            <label class="breadcrumb" data-test="breadcrumb">
                <ng-container *ngIf="deal$ | async as deal">
                    <ng-container *ngIf="!!deal.id">
                        <mat-icon>work_history</mat-icon>
                        <a [routerLink]="'../../deals/' + deal.id">{{deal.projectName.text}}</a>
                        <mat-icon>chevron_right</mat-icon>
                    </ng-container>
                </ng-container>
                <ng-container>
                    <mat-icon>domain</mat-icon>
                    {{fundName.name.text}}
                </ng-container>
            </label>
            <div class="page-content">

                <ng-container *ngIf="(fundValuation$ | async) as fundValuation">
                    <valumize-info-panel *ngIf="fundValuation.status === 'ERROR'" [appearance]="'ERROR'" [message]="fundValuation.errorMessage"></valumize-info-panel>
                </ng-container>
                <ng-container *ngIf="(fundReport$ | async)?.fundReport as fundReport">
                    <valumize-info-panel *ngIf="fundReport.status === 'ERROR'" [appearance]="'ERROR'" [message]="fundReport.errorMessage"></valumize-info-panel>
                </ng-container>

                <form [formGroup]="nameForm">
                    <div>
                        <h1 *ngIf="!fundNameIsEditable" data-test="fund-name" style="margin-top: 7px" title="Last modified: {{name.modDate | timestamp}}">{{name.text}}</h1>
                        <mat-form-field *ngIf="fundNameIsEditable" appearance="fill">
                            <input data-test="fund-name-input"
                                   formControlName="fundName"
                                   matInput
                                   placeholder="Fund Name">
                        </mat-form-field>
                    </div>
                    <div class="name-actions">
                        <button (click)="editMode()" *ngIf="!fundNameIsEditable" [disabled]="(isEditDisabled$ | async)!" color="primary" data-test="edit-button" mat-icon-button>
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button (click)="save()" *ngIf="fundNameIsEditable" [disabled]="nameForm.invalid" color="primary" data-test="save-button" mat-icon-button>
                            <mat-icon>save</mat-icon>
                        </button>
                        <button (click)="cancel()" *ngIf="fundNameIsEditable" color="primary" data-test="cancel-button" mat-icon-button>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </div>
                </form>
                <valumize-fund-partnership-investments id="partnership-investments"></valumize-fund-partnership-investments>
                <ng-container *ngIf="(sourceDataset$ | async) === 'master'">
                    <valumize-fund-treemap-actual class="component" id="treemap-actual"></valumize-fund-treemap-actual>
                </ng-container>
                <div class="component-wrapper">
                    <valumize-fund-details class="component" id="fund-details" style="flex: 1.9;"></valumize-fund-details>
                    <valumize-fund-report-details class="component" id="fund-report-details" style="flex: 1.1;"></valumize-fund-report-details>
                </div>
                <valumize-fund-seller-position class="component" id="seller-position"></valumize-fund-seller-position>
                <ng-container *ngIf="(sourceDataset$ | async) === 'master'">
                    <valumize-fund-undrawn-valuation id="undrawn-valuation"></valumize-fund-undrawn-valuation>
                    <valumize-fund-return-summary id="return-summary"></valumize-fund-return-summary>
                    <valumize-fund-cashflows-graph></valumize-fund-cashflows-graph>
                    <valumize-fund-cashflows id="fund-cashflows"></valumize-fund-cashflows>
                </ng-container>
                <br/>
                <br/>
                <br/>
            </div>
        </ng-container>
    </mat-sidenav-content>
</mat-sidenav-container>


<mat-card class="detail-card" *ngIf="dealDetails$ | async as dealDetails">
    <mat-card-header class="detail-card-header">
        <mat-card-title data-test="title">Deal Details</mat-card-title>
    </mat-card-header>
    <mat-card-content class="detail-card-content">
        <form [formGroup]="dealDetailsForm">
            <div class="key-value-pair-section">
                <div class="key-value-pair-column">
                    <div>
                        <div class="key-value-pair">
                            <div class="key-value-item">Number of Funds</div>
                            <div class="key-value-item">{{dealDetails.dealFunds.dealFundRecords.length}}</div>
                        </div>
                        <div class="key-value-pair">
                            <div class="key-value-item">Total Deal Size</div>
                            <div class="key-value-item">{{dealDetails.dealFunds.totalAdjustedCommitment | traceableFormat}}</div>
                        </div>
                        <div class="key-value-pair">
                            <div class="key-value-item">Total NAV</div>
                            <div class="key-value-item">{{dealDetails.dealFunds.totalNavBuyerStake | traceableFormat}}</div>
                        </div>
                        <div class="key-value-pair">
                            <div class="key-value-item">Bid Price</div>
                            <div class="key-value-item">{{dealDetails.dealFunds.totalBidPriceBuyerStake | traceableFormat}}</div>
                        </div>
                        <div class="key-value-pair">
                            <div class="key-value-item">Discount to NAV</div>
                            <div class="key-value-item">{{dealDetails.dealFunds.totalDiscountToNav | traceableFormat}}</div>
                        </div>
                    </div>
                </div>

                <div class="key-value-pair-column">
                    <div>
                        <div class="key-value-pair" *ngIf="seller$ | async as seller">
                            <div class="key-value-item">Seller</div>
                            <div class="key-value-item">{{seller.name | traceableFormat}}</div>
                        </div>
                        <div class="key-value-pair">
                            <div class="key-value-item">Source</div>
                            <div class="key-value-item">
                                <ng-container *ngIf="!dealDetails.dealDetailsIsEditable; else editSource">
                                    {{dealDetails.deal.source| traceableFormat}}
                                </ng-container>
                                <ng-template #editSource>
                                    <mat-form-field>
                                        <input matInput formControlName="source" id="source">
                                    </mat-form-field>
                                </ng-template>
                            </div>
                        </div>
                        <div class="key-value-pair">
                            <div class="key-value-item">Type of Process</div>
                            <div *ngIf="!dealDetails.dealDetailsIsEditable"
                                 class="key-value-item">{{dealDetails.deal.processType.code | codeFormat: codeTableProcessType | async}}</div>
                            <mat-form-field *ngIf="dealDetails.dealDetailsIsEditable">
                                <mat-select formControlName="processType">
                                    <mat-option *ngFor="let proccessType of (selectCodesProcessType$ | async)"
                                                [value]="proccessType.code">{{ proccessType.translations?.["en"] }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="key-value-pair">
                            <div class="key-value-item">Deal Captain</div>
                            <div class="key-value-item">
                                <ng-container *ngIf="!dealDetails.dealDetailsIsEditable; else editDealCaptain">
                                    {{dealDetails.deal.dealCaptain | traceableFormat}}
                                </ng-container>
                                <ng-template #editDealCaptain>
                                    <mat-form-field>
                                        <input matInput formControlName="dealCaptain" id="dealCaptain">
                                    </mat-form-field>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="key-value-pair-column">
                    <div>
                        <div class="key-value-pair">
                            <div class="key-value-item required-asterisk">Currency</div>
                            <div *ngIf="!dealDetails.dealDetailsIsEditable"
                                 class="key-value-item">{{dealDetails.deal.currencyIso.code | codeFormat: codeTableCurrency | async}}</div>
                            <mat-form-field *ngIf="dealDetails.dealDetailsIsEditable">
                                <input type="text"
                                       aria-label="Currency"
                                       matInput
                                       formControlName="currencyIso"
                                       [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let currency of filteredCurrencies$ | async" [value]="currency.code">
                                        <span>{{ currency.code }}</span> | <small>{{ currency.translations?.["en"] }}</small>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div class="key-value-pair">
                            <div class="key-value-item">Secondary Carry</div>
                            <div class="key-value-item">
                                <ng-container *ngIf="!dealDetails.dealDetailsIsEditable; else editSecondaryCarry">
                                    {{dealDetails.deal.secondaryCarry | traceableFormat}}
                                </ng-container>
                                <ng-template #editSecondaryCarry>
                                    <mat-form-field>
                                        <input matInput type="number" formControlName="secondaryCarry" id="secondaryCarry">
                                    </mat-form-field>
                                </ng-template>
                            </div>
                        </div>
                        <div class="key-value-pair">
                            <div class="key-value-item">Secondary Hurdle</div>
                            <div class="key-value-item">
                                <ng-container *ngIf="!dealDetails.dealDetailsIsEditable; else editSecondaryHurdle">
                                    {{dealDetails.deal.secondaryHurdle | traceableFormat}}
                                </ng-container>
                                <ng-template #editSecondaryHurdle>
                                    <mat-form-field>
                                        <input matInput type="number" formControlName="secondaryHurdle" id="secondaryHurdle">
                                    </mat-form-field>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </mat-card-content>
    <mat-card-actions class="detail-card-actions">
        <button (click)="edit()"
                *ngIf="!dealDetails.dealDetailsIsEditable"
                [disabled]="(isEditDisabled$ | async)!"
                color="primary"
                data-test="edit-button"
                mat-icon-button>
            <mat-icon>edit</mat-icon>
        </button>
        <button (click)="save(dealDetails.deal)"
                [disabled]="dealDetailsForm.invalid"
                *ngIf="dealDetails.dealDetailsIsEditable"
                color="primary"
                data-test="save-button"
                mat-icon-button>
            <mat-icon>save</mat-icon>
        </button>
        <button (click)="cancel()"
                *ngIf="dealDetails.dealDetailsIsEditable"
                color="primary"
                data-test="cancel-button"
                mat-icon-button>
            <mat-icon>cancel</mat-icon>
        </button>
    </mat-card-actions>
</mat-card>


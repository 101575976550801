<h2 mat-dialog-title>Add new Deal</h2>
<mat-dialog-content>
    <form [formGroup]="dealForm">
        <div class="d-flex">
            <div>
                <mat-form-field appearance="fill">
                    <mat-label>Closing Date</mat-label>
                    <input matInput
                           formControlName="closingDate"
                           id="closingDate"
                           [matDatepicker]="closingDatePicker">
                    <mat-hint>dd/MM/yyyy</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="closingDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #closingDatePicker></mat-datepicker>
                </mat-form-field>
            </div>
            <br>
            <div>
                <mat-form-field appearance="fill">
                    <input matInput
                           formControlName="dealName"
                           data-test="deal-name-input"
                           placeholder="Deal Name">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <input type="text"
                           aria-label="Currency"
                           matInput
                           formControlName="currencyIso"
                           placeholder="Deal Currency"
                           [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let currency of filteredCurrencies$ | async" [value]="currency.code">
                            <span>{{ currency.code }}</span> | <small>{{ currency.translations?.["en"] }}</small>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" mat-dialog-close data-test="save-button-bottom" [disabled]="dealForm.invalid" (click)="createDeal()">Add Deal</button>
    <button mat-stroked-button mat-dialog-close data-test="cancel-button">Cancel</button>
</mat-dialog-actions>

import {Injectable} from "@angular/core";
import {ReportApiService} from "../generated-sources/api/report";
import {Observable} from "rxjs";
import {DocumentMetadata} from "../models/document-metadata";

@Injectable({
    providedIn: "root"
})
export class ReportService {

    constructor(private readonly reportApiService: ReportApiService) {
    }

    getProjectReport(projectName: string, dealIds: number[], projectClosingDate?: string, firstRoundOffersDeadline?: string) {
        return this.reportApiService.getProjectReport(projectName, dealIds, projectClosingDate, firstRoundOffersDeadline);
    }

    getAllProjectReports(): Observable<DocumentMetadata[]> {
        return this.reportApiService.getAllProjectReports();
    }
}

<h2 mat-dialog-title>Add new General Partner</h2>
<mat-dialog-content>
    <p>If the general partner already exists, please select it.</p>
    <form [formGroup]="generalPartnerForm">
        <div>
            <div>
                <mat-form-field appearance="fill">
                    <mat-label>General Partner Name</mat-label>
                    <input matInput
                           formControlName="generalPartnerName"
                           data-test="general-partner-name-input"
                           (input)="onSearch()">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-label>General Partner Quality</mat-label>
                    <mat-select formControlName="generalPartnerQuality">
                        <mat-option *ngFor="let code of selectCodesGpQuality$ | async" [value]="code.code">
                            <span>{{code.code}}</span> | <small>{{code.translations?.["en"]}}</small>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>
    <table mat-table [dataSource]="(filteredGeneralPartners$ | async) || []">
        <ng-container matColumnDef="name" sticky>
            <th mat-header-cell *matHeaderCellDef>General Partner</th>
            <td mat-cell *matCellDef="let element">{{element.name | traceableFormat}}</td>
        </ng-container>

        <ng-container matColumnDef="quality" sticky>
            <th mat-header-cell *matHeaderCellDef>Quality</th>
            <td mat-cell *matCellDef="let element">{{element.quality.code | codeFormat: codeTableGpQuality | async}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row mat-dialog-close *matRowDef="let row; columns: columns" (click)="saveGeneralPartnerOnFund(row.id)"></tr>
    </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" mat-dialog-close
            [disabled]="generalPartnerForm.invalid"
            (click)="createGeneralPartner()">
        Add General Partner
    </button>
    <button mat-stroked-button mat-dialog-close data-test="cancel-button">Cancel</button>
</mat-dialog-actions>

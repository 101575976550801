/* eslint-disable arrow-body-style */
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {catchError, exhaustMap, map, of, switchMap, withLatestFrom} from "rxjs";
import {FundService} from "../../services/fund.service";
import {
    FundActions,
    FundDependencyAction,
    FundDetailActions,
    FundInvestmentActions,
    FundListActions,
    FundNameActions,
    FundNoteActions,
    FundReportActions,
    FundValuationActions,
    FundValuationAggregatedCashflowAction,
    ReturnSummaryActions
} from "../fund.actions";
import {NoteService} from "../../../shared/services/note/note.service";
import {Store} from "@ngrx/store";
import {selectHasBaselineFund, selectSelectedFund, selectSelectedFundId, selectSelectedFundNotes} from "./fund.selectors";
import {Fund} from "../../models/fund";
import {EMPTY_FUND} from "../fund.reducer";
import {DecimalFormatPipe} from "../../../shared/pipes/decimal-format/decimal-format.pipe";
import {DealActions, SellerPositionActions} from "../../../deal/store/deal.actions";
import {selectSelectedSourceDataset} from "../../../import/store/import.selectors";
import {tap} from "rxjs/operators";
import {EMPTY_SELLER_POSITION} from "../../../deal/store/deal.reducer";
import {selectSelectedFundValuationId} from "../fund-valuation/fund-valuation.selectors";
import {selectSelectedDealId} from "../../../deal/store/deal/deal.selectors";
import {Router} from "@angular/router";
import {selectSelectedGeneralPartnerId} from "../general-partner/general-partner.selectors";
import {selectSelectedFundReportId} from "../fund-report/fund-report.selectors";

// #############################################################################################################
// Fund LIST EFFECTS - START
// #############################################################################################################

export const initFundOverview = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(FundListActions.open),
            map(() => FundListActions.load())
        );
    },
    {functional: true}
);

export const loadFundList = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundService = inject(FundService)) => {
        return actions$.pipe(
            ofType(FundListActions.load),
            withLatestFrom(store$.select(selectSelectedSourceDataset)),
            exhaustMap(([action, dataset]) =>
                fundService.getFunds(dataset).pipe(
                    map((funds) => FundListActions.loadsuccess({funds})),
                    catchError((error: { message: string }) =>
                        of(FundListActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const loadFundListOnManageFundsDialog = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(FundListActions.loadfordeal),
            map(() => FundListActions.load())
        );
    },
    {functional: true}
);

// #############################################################################################################
// FUND LIST EFFECTS - END
// #############################################################################################################

export const initFundDetailsPage = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(FundActions.open),
            map((action) =>
                FundActions.load({fundId: action.fundId, fundReportId: action.fundReportId, fundValuationId: action.fundValuationId, dealId: action.dealId}))
        );
    },
    {functional: true}
);

export const loadDealForFundAfterFundOpen = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(FundActions.load),
            map((action) => action.dealId
                ? DealActions.loaddealforfund({dealId: action.dealId})
                : DealActions.nodealforfund())
        );
    },
    {functional: true}
);

export const loadFundInvestmentAfterFundOpen = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(FundActions.load),
            map((action) => action.fundReportId
                ? FundInvestmentActions.loadall({fundId: action.fundId, fundReportId: action.fundReportId, fundValuationId: action.fundValuationId})
                : FundInvestmentActions.donothing())
        );
    },
    {functional: true}
);

export const loadFundReportAfterFundOpen = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(FundActions.load),
            map((action) => action.fundReportId
                ? FundReportActions.load({fundId: action.fundId, fundReportId: action.fundReportId, fundValuationId: action.fundValuationId})
                : FundReportActions.loadall({fundId: action.fundId, fundIsMergeTarget: false}))
        );
    },
    {functional: true}
);

export const loadFundValuationAfterFundOpen = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(FundActions.load),
            map((action) => !!action.fundReportId && !!action.fundValuationId
                ? FundValuationActions.load({fundId: action.fundId, fundReportId: action.fundReportId, fundValuationId: action.fundValuationId})
                : FundValuationActions.loadall({fundId: action.fundId}))
        );
    },
    {functional: true}
);

export const loadSellerPositionAfterFundOpen = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(FundActions.load),
            map((action) => !!action.dealId && !!action.fundValuationId
                ? SellerPositionActions.load({dealId: action.dealId, fundValuationId: action.fundValuationId})
                : SellerPositionActions.loadsuccess({sellerPosition: EMPTY_SELLER_POSITION}))
        );
    },
    {functional: true}
);

export const loadFund = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundService = inject(FundService)) => {
        return actions$.pipe(
            ofType(FundActions.load, FundActions.loadforasset),
            withLatestFrom(store$.select(selectHasBaselineFund)),
            exhaustMap(([action, hasBaselineFund]) =>
                fundService.getFund(action.fundId).pipe(
                    switchMap((fundWithGP) => {
                        const actionSuccess = (action.type === FundActions.load.type)
                            ? FundActions.loadsuccess({fundWithGP})
                            : FundActions.loadforassetsuccess({fundWithGP});

                        return fundWithGP.fund.mergeTargetId && !hasBaselineFund
                            ? of(actionSuccess, FundActions.loadbaseline({fundId: fundWithGP.fund.mergeTargetId}))
                            : of(actionSuccess);
                    }),
                    catchError((error: { message: string }) => of(FundActions.loaderror({errorMsg: error.message})))
                )
            )
        );
    },
    {functional: true}
);

export const loadBaselineFund = createEffect(
    (actions$ = inject(Actions), fundService = inject(FundService)) => {
        return actions$.pipe(
            ofType(FundActions.loadbaseline),
            exhaustMap((action) =>
                fundService.getFund(action.fundId).pipe(
                    map((fundWithGP) => FundActions.loadbaselinesuccess({fundWithGP})),
                    catchError((error: { message: string }) =>
                        of(FundActions.loaderror({errorMsg: "Baseline Fund" + error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const loadFundNotes = createEffect(
    (actions$ = inject(Actions), noteService = inject(NoteService)) => {
        return actions$.pipe(
            ofType(FundActions.load, FundNoteActions.load),
            exhaustMap((action) => {
                const inContext = "inContext" in action
                    ? action.inContext
                    : `/funds/fund_id=${action.fundId}/`;
                return noteService.getNotes(inContext).pipe(
                    map((notes) => FundNoteActions.loadsuccess({notes})),
                    catchError((error: { message: string }) =>
                        of(FundNoteActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const saveFundNote = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), noteService = inject(NoteService)) => {
        return actions$.pipe(
            ofType(FundNoteActions.save),
            withLatestFrom(store$.select(selectSelectedFundNotes)),
            exhaustMap(([action, state]) => {
                return noteService.save(action.note, action.context).pipe(
                    map(() => FundNoteActions.load({inContext: state.baseContext})),
                    catchError((error: { message: string }) =>
                        of(FundNoteActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const deleteFundNote = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), noteService = inject(NoteService)) => {
        return actions$.pipe(
            ofType(FundNoteActions.delete),
            withLatestFrom(store$.select(selectSelectedFundNotes)),
            exhaustMap(([action, state]) => {
                if (action.note.id === undefined) {
                    return of();
                }
                return noteService.delete(action.note.id).pipe(
                    map(() => FundNoteActions.load({inContext: state.baseContext})),
                    catchError((error: { message: string }) =>
                        of(FundNoteActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const saveFundName = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundService = inject(FundService)) => {
        return actions$.pipe(
            ofType(FundNameActions.save),
            withLatestFrom(store$.select(selectSelectedFund), store$.select(selectSelectedSourceDataset), store$.select(selectSelectedGeneralPartnerId)),
            exhaustMap(([action, state, dataset, generalPartnerId]) => {
                const selectedFund: Fund = state.fund;
                if (selectedFund.id) {
                    const fundId = selectedFund.id;
                    return fundService.saveFund({
                        ...selectedFund,
                        ...{
                            name: {
                                ...selectedFund.name,
                                text: action.fundName
                            }
                        }
                    }, fundId, dataset, generalPartnerId).pipe(
                        map((fund) => FundActions.savedloadsuccess({fund})),
                        catchError((error: { message: string }) => of(FundActions.loaderror({errorMsg: error.message})))
                    );
                } else {
                    return of(FundActions.loaderror({errorMsg: "Fund could not be found"}));
                }
            })
        );
    },
    {functional: true}
);

export const saveGeneralPartnerOnFund = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundService = inject(FundService)) => {
        return actions$.pipe(
            ofType(FundActions.savegeneralpartneronfund),
            withLatestFrom(
                store$.select(selectSelectedFund),
                store$.select(selectSelectedSourceDataset),
                store$.select(selectSelectedFundReportId),
                store$.select(selectSelectedFundValuationId),
                store$.select(selectSelectedDealId)
            ),
            exhaustMap(([action, selectedFund, dataset, fundReportId, fundValuationId, dealId]) => {
                return fundService.saveFund(selectedFund.fund, selectedFund.fund.id, dataset, action.generalPartnerId).pipe(
                    map((fund) => fund.id
                        ? FundActions.load({fundId: fund.id, fundReportId, fundValuationId, dealId})
                        : FundActions.loaderror({errorMsg: "Error while loading the fund, please refresh the page"})
                    ),
                    catchError((error: { message: string }) => of(DealActions.loaderror({errorMsg: error.message}))));
            })
        );
    },
    {functional: true}
);

export const createFund = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundService = inject(FundService)) => {
        return actions$.pipe(
            ofType(FundActions.create),
            withLatestFrom(store$.select(selectSelectedSourceDataset)),
            exhaustMap(([action, dataset]) => {
                return fundService.saveFund({
                    ...EMPTY_FUND,
                    ...{
                        name: {
                            ...EMPTY_FUND.name,
                            text: action.fundName
                        }
                    }
                }, undefined, dataset).pipe(
                    map((fund) => FundActions.createsuccess({fund})),
                    catchError((error: { message: string }) => of(FundActions.loaderror({errorMsg: error.message})))
                );
            })
        );
    },
    {functional: true}
);

export const navigateToFundDetailsPageAfterCreate = createEffect(
    (actions$ = inject(Actions), router$ = inject(Router)) => {
        return actions$.pipe(
            ofType(FundActions.createsuccess),
            tap((action) => router$.navigate(["/funds", action.fund.id])));
    },
    {functional: true, dispatch: false}
);

export const saveFundDetails = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundService = inject(FundService)) => {
        return actions$.pipe(
            ofType(FundDetailActions.save),
            withLatestFrom(store$.select(selectSelectedFund), store$.select(selectSelectedSourceDataset), store$.select(selectSelectedGeneralPartnerId)),
            exhaustMap(([action, state, dataset, generalPartnerId]) => {
                const selectedFund: Fund = state.fund;
                if (selectedFund.id) {
                    const fundId = selectedFund.id;
                    return fundService.saveFund({
                        ...selectedFund,
                        ...{
                            vintageYear: {
                                ...selectedFund.vintageYear,
                                date: action.vintageYear
                            },
                            purchaseYear: {
                                ...selectedFund.purchaseYear,
                                date: action.purchaseYear
                            },
                            endYear: {
                                ...selectedFund.endYear,
                                date: action.endYear
                            },
                            managementFee: {
                                ...selectedFund.managementFee,
                                fraction: DecimalFormatPipe.transformPercentToFraction(action.managementFee)
                            },
                            carry: {
                                ...selectedFund.carry,
                                fraction: DecimalFormatPipe.transformPercentToFraction(action.carry)
                            },
                            hurdleRate: {
                                ...selectedFund.hurdleRate,
                                fraction: DecimalFormatPipe.transformPercentToFraction(action.hurdleRate)
                            },
                            secondaryManagementFeeRate: {
                                ...selectedFund.secondaryManagementFeeRate,
                                fraction: DecimalFormatPipe.transformPercentToFraction(action.secondaryManagementFee)
                            },
                            secondaryCarryRate: {
                                ...selectedFund.secondaryCarryRate,
                                fraction: DecimalFormatPipe.transformPercentToFraction(action.secondaryCarry)
                            },
                            secondaryHurdleRate: {
                                ...selectedFund.secondaryHurdleRate,
                                fraction: DecimalFormatPipe.transformPercentToFraction(action.secondaryHurdleRate)
                            },
                            stage: {
                                ...selectedFund.stage,
                                code: action.stage
                            },
                            managementFeeCalculationMethod: {
                                ...selectedFund.managementFeeCalculationMethod,
                                code: action.managementFeeCalculation
                            },
                            regions: action.regions,
                            currencyIso: {
                                ...selectedFund.currencyIso,
                                code: action.currencyIso
                            },
                            country: {
                                ...selectedFund.country,
                                code: action.country ?? undefined
                            },
                            size: {
                                ...selectedFund.size,
                                amount: DecimalFormatPipe.transformToMillionsNum(action.size)
                            },
                            mainFundSize: {
                                ...selectedFund.mainFundSize,
                                amount: DecimalFormatPipe.transformToMillionsNum(action.mainFundSize)
                            }
                        }
                    }, fundId, dataset, generalPartnerId).pipe(
                        map((fund) => FundDetailActions.savesuccess({fund})),
                        catchError((error: { message: string }) => of(FundActions.loaderror({errorMsg: error.message})))
                    );
                } else {
                    return of(FundActions.loaderror({errorMsg: "Fund could not be found"}));
                }
            })
        );
    },
    {functional: true}
);

export const loadSellerPositionAfterPartnerInvAES = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(FundDetailActions.savesuccess),
            withLatestFrom(store$.select(selectSelectedFundValuationId), store$.select(selectSelectedDealId)),
            map(([, fundValuationId, dealId]) => !!dealId && !!fundValuationId
                ? SellerPositionActions.load({dealId, fundValuationId})
                : SellerPositionActions.loadsuccess({sellerPosition: EMPTY_SELLER_POSITION}))
        );
    },
    {functional: true}
);

export const calcReturnSummaryAfterFundDetailSaveSuccess = createEffect(
    (actions$ = inject(Actions), store$ = inject(Store)) => {
        return actions$.pipe(
            ofType(FundDetailActions.savesuccess),
            withLatestFrom(
                store$.select(selectSelectedFundId),
                store$.select(selectSelectedFundValuationId)
            ),
            map(([, fundId, fundValuationId]) => !!fundId && !!fundValuationId
                ? ReturnSummaryActions.calc({fundId, fundValuationId})
                : ReturnSummaryActions.calcerror({errorMsg: "Fund or Valuation ID missing"}))
        );
    },
    {functional: true}
);

export const loadAllFundCashflowsParallelAfterFundDetailSaveSuccess = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(FundDetailActions.savesuccess),
            map(() => FundValuationAggregatedCashflowAction.loadallparallel())
        );
    },
    {functional: true}
);

export const setMergeTargetId = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundService = inject(FundService)) => {
        return actions$.pipe(
            ofType(FundActions.setmergetargetid),
            withLatestFrom(store$.select(selectSelectedFund), store$.select(selectSelectedSourceDataset), store$.select(selectSelectedGeneralPartnerId)),
            exhaustMap(([action, selectedFundData, dataset, generalPartnerId]) => {
                const selectedFund: Fund = selectedFundData.fund;
                const fundId = selectedFund.id;
                if (fundId) {
                    return fundService.saveFund({
                        ...selectedFund,
                        ...{
                            mergeTargetId: action.mergeTargetId
                        }
                    }, fundId, dataset, generalPartnerId).pipe(
                        switchMap((fund) => of(
                            FundActions.savedloadsuccess({fund}),
                            FundReportActions.setmergetargetid({fundId, mergeTargetId: undefined}))
                        ),
                        tap(() => window.location.reload()),
                        catchError((error: { message: string }) => of(FundActions.loaderror({errorMsg: error.message})))
                    );
                } else {
                    return of(FundActions.loaderror({errorMsg: "Fund could not be found"}));
                }
            })
        );
    },
    {functional: true}
);


// #############################################################################################################
// Fund Dependencies - START
// #############################################################################################################

export const loadSellerPositionAsDependency = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(FundDependencyAction.loaddependencies),
            withLatestFrom(store$.select(selectSelectedFundValuationId), store$.select(selectSelectedDealId)),
            map(([action, fundValuationId, dealId]) => (action.loadSellerPosition)
                ? (!!dealId && !!fundValuationId
                    ? SellerPositionActions.load({dealId, fundValuationId})
                    : SellerPositionActions.loadsuccess({sellerPosition: EMPTY_SELLER_POSITION}))
                : FundDependencyAction.donotloadsellerposition()
            )
        );
    },
    {functional: true}
);

export const calcReturnSummaryAsDependency = createEffect(
    (actions$ = inject(Actions), store$ = inject(Store)) => {
        return actions$.pipe(
            ofType(FundDependencyAction.loaddependencies),
            withLatestFrom(
                store$.select(selectSelectedFundId),
                store$.select(selectSelectedFundValuationId)
            ),
            map(([action, fundId, fundValuationId]) => (action.calcReturnSummary)
                ? (fundId && fundValuationId
                    ? ReturnSummaryActions.calc({fundId, fundValuationId})
                    : ReturnSummaryActions.calcerror({errorMsg: "Fund or Valuation ID missing"}))
                : FundDependencyAction.donotcalcreturnsummary()
            )
        );
    },
    {functional: true}
);

export const loadAllFundCashflowsParallelAsDependency = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(FundDependencyAction.loaddependencies),
            map((action) => action.loadCashflows
                ? FundValuationAggregatedCashflowAction.loadallparallel()
                : FundDependencyAction.donotloadcashflows()
            )
        );
    },
    {functional: true}
);

// #############################################################################################################
// Fund Dependencies - END
// #############################################################################################################

import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Fund} from "../models/fund";
import {FundApiService} from "../generated-sources/api";
import {FundWithGeneralPartner} from "../models/fund-with-general-partner";

@Injectable({
    providedIn: "root"
})
export class FundService {

    constructor(private readonly fundApiService: FundApiService) {
    }

    getFunds(dataset?: string): Observable<Fund[]> {
        return this.fundApiService.getFunds(dataset);
    }

    getFund(id: number): Observable<FundWithGeneralPartner> {
        return this.fundApiService.getFund(id);
    }

    saveFund(fund: Fund, id?: number, dataset?: string, generalPartnerId?: number) {
        return (id) ? this.fundApiService.updateFund(id, fund, generalPartnerId) : this.fundApiService.createFund(fund, dataset);
    }
}

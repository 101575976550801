/* eslint-disable arrow-body-style */
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {DealActions, SellerActions, SellerListActions} from "../deal.actions";
import {catchError, exhaustMap, map, of} from "rxjs";
import {Store} from "@ngrx/store";
import {EMPTY_SELLER} from "../deal.reducer";
import {SellerService} from "../../services/seller.service";

export const loadSellerList = createEffect(
    (actions$ = inject(Actions), sellerService = inject(SellerService)) => {
        return actions$.pipe(
            ofType(SellerListActions.load),
            exhaustMap(() =>
                sellerService.getSellers().pipe(
                    map((sellers) => SellerListActions.loadsuccess({sellers})),
                    catchError((error: { message: string }) => of(SellerListActions.loaderror({errorMsg: error.message}))))
            )
        );
    },
    {functional: true}
);

export const loadSeller = createEffect(
    (actions$ = inject(Actions), sellerService = inject(SellerService)) => {
        return actions$.pipe(
            ofType(SellerActions.load),
            exhaustMap((action) => {
                const sellerId = action.sellerId;
                return sellerService.getSeller(sellerId).pipe(
                    map((seller) => SellerActions.loadsuccess({seller})),
                    catchError((error: { message: string }) => of(SellerActions.loaderror({errorMsg: error.message}))));
            })
        );
    },
    {functional: true}
);

export const createSeller = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), sellerService = inject(SellerService)) => {
        return actions$.pipe(
            ofType(SellerActions.create),
            exhaustMap((action) => {
                return sellerService.saveSeller({
                        ...EMPTY_SELLER,
                        ...{
                            name: {
                                ...EMPTY_SELLER.name,
                                text: action.sellerName
                            }
                        }
                    }
                ).pipe(
                    map((seller) => SellerActions.loadsuccess({seller})),
                    catchError((error: { message: string }) => of(SellerActions.loaderror({errorMsg: error.message}))));
            })
        );
    },
    {functional: true}
);

export const saveSellerOnDealAfterLoadingSeller = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(SellerActions.loadsuccess),
            map((action) => action.seller.id
                ? DealActions.savesellerondeal({sellerId: action.seller.id})
                : SellerActions.loaderror({errorMsg: ""})));
    },
    {functional: true}
);

import {createActionGroup, emptyProps, props} from "@ngrx/store";
import {FundInvestment} from "../models/fund-investement";
import {Fund} from "../models/fund";
import {FundValuation} from "../models/fund-valuation";
import {Note} from "../../shared/model/note";
import {FundReport} from "../models/fund-report";
import {FundValuationScenario} from "../models/fund-valuation-scenario";
import {PartnershipInvestment} from "../models/partnership-investment";
import {AggregatedFundCashflows} from "../models/aggregated-fund-cashflows";
import {ScenarioType} from "../../shared/model/scenario-type";
import {FundReturnSummary} from "../models/fund-return-summary";
import {TraceableCode, TraceableMoney} from "../../shared/model/traceable";
import {GeneralPartner} from "../models/general-partner";
import {FundWithGeneralPartner} from "../models/fund-with-general-partner";

export const FundListActions = createActionGroup({
    source: "Fund List Action",
    events: {
        open: emptyProps(),
        load: emptyProps(),
        loadForDeal: emptyProps(),
        loadSuccess: props<{ funds: Fund[] }>(),
        loadError: props<{ errorMsg: string }>()
    }
});

export const FundActions = createActionGroup({
    source: "Fund Actions",
    events: {
        open: props<{ fundId: number; fundReportId?: number; fundValuationId?: number; dealId?: number }>(),
        create: props<{ fundName: string }>(),
        createSuccess: props<{ fund: Fund }>(),
        navigateToFundSuccess: emptyProps(),
        saveGeneralPartnerOnFund: props<{ generalPartnerId: number }>(),
        load: props<{ fundId: number; fundReportId?: number; fundValuationId?: number; dealId?: number }>(),
        loadSuccess: props<{ fundWithGP: FundWithGeneralPartner }>(),
        loadForAsset: props<{ fundId: number }>(),
        loadForAssetSuccess: props<{ fundWithGP: FundWithGeneralPartner }>(),
        loadForAssetDoNothing: emptyProps(),
        loadBaseline: props<{ fundId: number }>(),
        loadBaselineSuccess: props<{ fundWithGP: FundWithGeneralPartner }>(),
        savedLoadSuccess: props<{ fund: Fund }>(),
        loadError: props<{ errorMsg: string }>(),
        clearFundState: emptyProps(),
        setMergeTargetId: props<{ mergeTargetId?: number }>()
    }
});

export const FundNameActions = createActionGroup({
    source: "Fund Name Action",
    events: {
        edit: emptyProps(),
        save: props<{ fundName: string }>(),
        cancel: emptyProps()
    }
});

export const FundDetailActions = createActionGroup({
    source: "Fund Detail Action",
    events: {
        edit: emptyProps(),
        save: props<{
            vintageYear: string | undefined;
            purchaseYear: string | undefined;
            endYear: string | undefined;
            managementFee: number | null;
            carry: number | null;
            hurdleRate: number | null;
            secondaryManagementFee: number | null;
            secondaryCarry: number | null;
            secondaryHurdleRate: number | null;
            stage: string;
            managementFeeCalculation: string;
            regions: TraceableCode[];
            currencyIso: string;
            country: string | null;
            size: number | null;
            mainFundSize: number | null;
        }>(),
        saveSuccess: props<{ fund: Fund }>(),
        cancel: emptyProps()
    }
});

export const FundReportActions = createActionGroup({
    source: "Fund Report Actions",
    events: {
        edit: emptyProps(),
        save: props<{
            fundId: number;
            fundReport: FundReport;
        }>(),
        saveSuccess: props<{ fundReport: FundReport }>(),
        calc: props<{ fundReport: FundReport }>(),
        calcSuccess: props<{ fundReport: FundReport }>(),
        cancel: emptyProps(),
        create: props<{ fundId: number; reportDate: string }>(),
        load: props<{ fundId: number; fundReportId: number; fundValuationId: number | undefined }>(),
        loadSuccess: props<{ fundReport: FundReport }>(),
        loadForAsset: props<{ fundId: number; fundReportId: number; fundValuationId: number | undefined }>(),
        loadForAssetSuccess: props<{ fundReport: FundReport }>(),
        loadForAssetDoNothing: emptyProps(),
        loadBaseline: props<{ fundId: number; fundReportId: number }>(),
        loadBaselineSuccess: props<{ fundReport: FundReport }>(),
        loadAll: props<{ fundId: number; fundIsMergeTarget: boolean }>(),
        loadAllSuccess: props<{ fundId: number; reports: FundReport[] }>(),
        loadedAllBaseline: props<{ reports: FundReport[] }>(),
        clearBaseline: emptyProps(),
        loaderror: props<{ errorMsg: string }>(),
        setMergeTargetId: props<{ fundId: number; mergeTargetId?: number }>()
    }
});


export const FundValuationActions = createActionGroup({
    source: "Fund Valuation Actions",
    events: {
        create: props<{
            fundId: number;
            fundReportId: number;
            reportDate: string;
            closingDate: string;
        }>(),
        created: props<{ fundValuation: FundValuation }>(),
        load: props<{ fundId: number; fundReportId: number; fundValuationId: number }>(),
        loadSuccess: props<{ fundValuation: FundValuation }>(),
        loadForAsset: props<{ fundId: number; fundReportId: number; fundValuationId: number }>(),
        loadForAssetSuccess: props<{ fundValuation: FundValuation }>(),
        loadForAssetDoNothing: emptyProps(),
        save: props<{ fundId: number; fundValuation: FundValuation }>(),
        saveLoadSuccess: props<{ fundId: number; fundValuation: FundValuation }>(),
        loadAll: props<{ fundId: number }>(),
        loadAllSuccess: props<{ fundId: number; valuations: FundValuation[] }>(),
        loadError: props<{ errorMsg: string }>()
    }
});

export const FundValuationScenarioActions = createActionGroup({
    source: "Fund Valuation Scenario Actions",
    events: {
        loadAll: props<{ fundId: number; fundValuationId: number }>(),
        loadallSuccess: props<{ scenarios: FundValuationScenario[] }>(),
        loadError: props<{ errorMsg: string }>()
    }
});

export const FundValuationAggregatedCashflowAction = createActionGroup({
    source: "Fund Valuation Aggregated Cashflow Actions",
    events: {
        load: props<{ scenarioId: number; scenarioType: ScenarioType }>(),
        loadAllParallel: emptyProps(),
        loadSuccess: props<{ aggregatedCashFlows: AggregatedFundCashflows; scenarioType: ScenarioType }>(),
        loadError: props<{ scenarioType: ScenarioType; errorMsg: string }>()
    }
});

export const FundInvestmentActions = createActionGroup({
    source: "Fund Investment Actions",
    events: {
        edit: emptyProps(),
        save: props<{ fundId: number; fundReportId: number; fundInvestment: FundInvestment }>(),
        load: props<{ fundId: number; fundReportId: number; fundInvestmentId: number }>(),
        loadSuccess: props<{ fundInvestment: FundInvestment }>(),
        loadforAsset: props<{ fundId: number; fundReportId: number; fundInvestmentId: number }>(),
        loadforAssetSuccess: props<{ fundInvestment: FundInvestment }>(),
        loadForAssetDoNothing: emptyProps(),
        savedLoadSuccess: props<{ fundInvestment: FundInvestment }>(),
        saveAll: props<{ fundId: number; fundReportId: number; fundInvestments: FundInvestment[] }>(),
        saveAllSuccess: props<{
            fundInvestments: FundInvestment[];
            fundId: number | undefined;
            fundReportId: number | undefined;
            fundValuationId: number | undefined;
        }>(),
        loadAll: props<{ fundId: number; fundReportId: number; fundValuationId: number | undefined }>(),
        loadAllSuccess: props<{
            fundInvestments: FundInvestment[];
            fundId: number | undefined;
            fundReportId: number | undefined;
            fundValuationId: number | undefined;
        }>(),
        loadError: props<{ errorMsg: string }>(),
        cancel: emptyProps(),
        doNothing: emptyProps()
    }
});

export const PartnershipInvestmentActions = createActionGroup({
    source: "Partnership Investment Actions",
    events: {
        load: props<{ fundId: number; fundReportId: number; fundValuationId: number | undefined }>(),
        loadSuccess: props<{ partnershipInvestment: PartnershipInvestment }>(),
        loadForAsset: props<{ fundId: number; fundReportId: number; fundValuationId: number | undefined }>(),
        loadForAssetSuccess: props<{ partnershipInvestment: PartnershipInvestment }>(),
        loadForAssetDoNothing: emptyProps(),
        loadAfterEdit: props<{ fundId: number; fundReportId: number; fundValuationId: number | undefined }>(),
        loadAfterEditSuccess: props<{ partnershipInvestment: PartnershipInvestment }>(),
        loadError: props<{ errorMsg: string }>(),
        edit: emptyProps(),
        // (!) PartnershipInvestmentActions.save is used to save on FundReport, FundValuation and FundValuationScenarios!
        save: props<{ partnershipInvestment: PartnershipInvestment }>(),
        saveAndLoadOnFundReportSuccess: props<{ fundReport: FundReport }>(),
        saveAndLoadOnFundValuationSuccess: props<{ fundValuation: FundValuation }>(),
        saveAndLoadOnAllFundValuationScenarioSuccess: props<{ scenarios: FundValuationScenario[] }>(),
        cancel: emptyProps()
    }
});

export const FundNoteActions = createActionGroup({
    source: "Fund Note Actions",
    events: {
        load: props<{ inContext: string }>(),
        loadSuccess: props<{ notes: Note[] }>(),
        loadError: props<{ errorMsg: string }>(),
        save: props<{ context: string; note: Note }>(),
        delete: props<{ note: Note }>()
    }
});

export const UndrawnValuationActions = createActionGroup({
    source: "Undrawn Valuation Actions",
    events: {
        edit: emptyProps(),
        save: props<{
            fundValuation: FundValuation;
            scenarios: FundValuationScenario[];
        }>(),
        saveSuccess: props<{ scenarios: FundValuationScenario[] }>(),
        calc: props<{
            fundValuation: FundValuation;
            scenarios: FundValuationScenario[];
        }>(),
        calcSuccess: props<{
            fundValuation: FundValuation;
            scenarios: FundValuationScenario[];
        }>(),
        calcError: props<{ errorMsg: string }>(),
        cancel: emptyProps()
    }
});

export const FundSellerPositionActions = createActionGroup({
    source: "Fund Seller Position Actions",
    events: {
        edit: emptyProps(),
        cancel: emptyProps()
    }
});

export const ReturnSummaryActions = createActionGroup({
    source: "Return Summary Actions",
    events: {
        edit: emptyProps(),
        save: props<{
            partnershipInvestment: PartnershipInvestment;
        }>(),
        cancel: emptyProps(),
        calc: props<{ fundId: number; fundValuationId: number }>(),
        calcSuccess: props<{ returnSummary: FundReturnSummary }>(),
        calcError: props<{ errorMsg: string }>(),
        calculateGoalSeek: props<{ targetTvpi: number }>(),
        calculateGoalSeekSuccess: props<{ bidPriceAdjustment: TraceableMoney }>(),
        calculateGoalSeekError: props<{ errorMsg: string }>(),
    }
});

export const FundDependencyAction = createActionGroup({
    source: "Fund Dependency Actions",
    events: {
        loadDependencies: props<{
            loadFundReport: boolean;
            calcReturnSummary: boolean;
            loadCashflows: boolean;
            loadSellerPosition: boolean;
        }>(),
        doNotLoadFundReport: emptyProps(),
        doNotCalcReturnSummary: emptyProps(),
        doNotLoadCashflows: emptyProps,
        doNotLoadSellerPosition: emptyProps(),
    }
});

export const GeneralPartnerListActions = createActionGroup({
    source: "General Partner List Actions",
    events: {
        load: emptyProps(),
        loadSuccess: props<{ generalPartners: GeneralPartner[] }>(),
        loadError: props<{ errorMsg: string }>()
    }
});

export const GeneralPartnerActions = createActionGroup({
    source: "General Partner Actions",
    events: {
        create: props<{ name: string; quality: string }>(),
        load: props<{ generalPartnerId: number }>(),
        loadSuccess: props<{ generalPartner: GeneralPartner }>(),
        loadError: props<{ errorMsg: string }>()
    }
});

export interface Code {
    id?: number;
    code?: string;
    table?: string;
    description?: string;
    parameters?: { [key: string]: string };
    locked?: boolean;
    active?: boolean;
    translations?: { [key: string]: string };
}

export enum CodeTableEnum {
    ACCELEX_TASK_INFO_STATUS = "valumize.accelex.taskInfoStatus",
    ASSET_CASHFLOWTYPE = "valumize.asset.CashflowType",
    ASSET_EARNING_BASE = "valumize.asset.EarningBase",
    ASSET_ESG = "valumize.asset.ESG",
    ASSET_GPINDICATION = "valumize.asset.GpIndication",
    ASSET_VALUATIONTYPE = "valumize.asset.ValuationType",
    FUND_FEECALCULATIONMETHOD = "valumize.fund.FeeCalculationMethod",
    FUND_GENERALPARTNERQUALITY = "valumize.fund.GeneralPartnerQuality",
    PROCESS_TYPE = "valumize.deal.ProcessType",
    SHARED_ASSESSMENT = "valumize.shared.Assessment",
    SHARED_COUNTRY = "valumize.shared.countryCode",
    SHARED_CURRENCY = "valumize.shared.Currency",
    SHARED_FISCALYEAREND = "valumize.shared.FiscalYearEnd",
    SHARED_REGIONS = "valumize.shared.Region",
    SHARED_SCENARIOTYPE = "valumize.shared.ScenarioType",
    SHARED_STAGES = "valumize.shared.Stage"
}

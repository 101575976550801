import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from "@angular/core";
import {AssetValuationScenario} from "../../../../models/asset-valuation/asset-valuation-scenario";
import {FinancialPerformanceRecord} from "../../../../models/asset/financial-performance-record";
import {FinancialPerformanceRecordsActions} from "../../../../store/asset.actions";
import {Store} from "@ngrx/store";
import {CodeTableEnum} from "../../../../../shared/model/code";
import {selectAssetFinancialHistory, selectIsAnyAssetFormInEditMode} from "../../../../store/asset/asset.selectors";
import {map, Subscription} from "rxjs";
import {isOfScenarioType} from "../../../../../shared/model/scenario-type";
import {selectFinancialPerformanceRecordsByScenarioType, selectSelectedExitDateAssumptionOperational} from "../../../../store/asset-valuation/asset-valuation.selectors";
import {EMPTY_CODE, EMPTY_MONEY_WITH_CURRENCY, TraceableCode} from "../../../../../shared/model/traceable";

@Component({
    selector: "valumize-asset-financial-operational-valuation-scenario",
    templateUrl: "./asset-financial-operational-valuation-scenario.component.html"
})
export class AssetFinancialOperationalValuationScenarioComponent implements OnInit, OnChanges, OnDestroy {

    @Input() assetId?: number;
    @Input() assetValuationId?: number;
    @Input() scenario?: AssetValuationScenario;
    @Input() recordsAreEditable = false;
    @Input() earningBase: TraceableCode = EMPTY_CODE;

    isEditDisabled$ = this.store.select(selectIsAnyAssetFormInEditMode);
    selectSelectedExitDateAssumptionOperational$ = this.store.select(selectSelectedExitDateAssumptionOperational);

    financialHistoryRecords: FinancialPerformanceRecord[] = [];
    financialPerformanceRecords: FinancialPerformanceRecord[] = [];
    codeTableScenarios = CodeTableEnum.SHARED_SCENARIOTYPE;
    codeTableEarningBase = CodeTableEnum.ASSET_EARNING_BASE;
    subscriptions: Subscription[] = [];

    constructor(private readonly store: Store, private readonly cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.fetchFinancialPerformance();

        this.subscriptions.push(
            this.store.select(selectAssetFinancialHistory).pipe(map((data) => {
                this.financialHistoryRecords = [...data.data];
            })).subscribe());

        if (this.scenario?.scenario.code && isOfScenarioType(this.scenario.scenario.code)) {
            this.subscriptions.push(
                this.store.select(selectFinancialPerformanceRecordsByScenarioType(this.scenario.scenario.code)).pipe(map((data) => {
                    this.financialPerformanceRecords = [...data];
                })).subscribe());
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.recordsAreEditable = changes["recordsAreEditable"]?.currentValue ?? this.recordsAreEditable;
        this.cdr.detectChanges();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    fetchFinancialPerformance(): void {
        if (this.assetId && this.assetValuationId && this.scenario?.id && this.scenario.scenario.code && isOfScenarioType(this.scenario.scenario.code)) {
            this.store.dispatch(FinancialPerformanceRecordsActions.load({
                assetId: this.assetId,
                assetValuationId: this.assetValuationId,
                scenarioId: this.scenario.id,
                scenarioType: this.scenario.scenario.code
            }));
        }
    }

    getCashflow(scenario: AssetValuationScenario) {
        return scenario.cashflows.at(0)?.amount ?? EMPTY_MONEY_WITH_CURRENCY;
    }

    onSave(financialPerformanceRecords: FinancialPerformanceRecord[], assetId?: number, assetValuationId?: number, scenarioId?: number, scenarioType?: string) {
        if (assetId && assetValuationId && scenarioId && scenarioType && isOfScenarioType(scenarioType)) {
            this.store.dispatch(FinancialPerformanceRecordsActions.save({
                assetId,
                assetValuationId,
                scenarioId,
                financialPerformanceRecords,
                scenarioType
            }));
        }
    }

    onEdit(financialPerformanceRecords: FinancialPerformanceRecord[], assetId?: number, assetValuationId?: number, scenarioId?: number, scenarioType?: string) {
        if (assetId && assetValuationId && scenarioId && scenarioType && isOfScenarioType(scenarioType)) {
            this.store.dispatch(FinancialPerformanceRecordsActions.edit({
                assetId,
                assetValuationId,
                scenarioId,
                financialPerformanceRecords,
                scenarioType
            }));
        }
    }

    onCancel(scenarioType?: string) {
        if (scenarioType && isOfScenarioType(scenarioType)) {
            this.fetchFinancialPerformance();
            this.store.dispatch(FinancialPerformanceRecordsActions.cancel({scenarioType}));
        }
    }

    onCalc(financialPerformanceRecords: FinancialPerformanceRecord[], assetId?: number, assetValuationId?: number, scenarioId?: number, scenarioType?: string) {
        if (assetId && assetValuationId && scenarioId && scenarioType && isOfScenarioType(scenarioType)) {
            this.store.dispatch(FinancialPerformanceRecordsActions.calc({
                assetId,
                assetValuationId,
                scenarioId,
                financialPerformanceRecords,
                scenarioType
            }));
        }
    }
}

import {Component, OnDestroy, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import {
    selectAssetSwot,
    selectAssetSwotEdit,
    selectGeneratedSwotAnalysis,
    selectGptSwotIsEditable,
    selectIsAnyAssetFormInEditMode,
    selectSelectedAsset
} from "../../store/asset/asset.selectors";
import {AssetSwotActions} from "../../store/asset.actions";
import {FormBuilder} from "@angular/forms";
import {map, Subscription} from "rxjs";
import {Swot} from "../../models/asset/swot";
import {DateUtil} from "../../../shared/utils/date-util";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmationComponent} from "../../../shared/components/confirmation/confirmation.component";

@Component({
    selector: "valumize-asset-swot",
    templateUrl: "./asset-swot.component.html",
    styleUrls: ["./asset-swot.component.scss"]
})
export class AssetSwotComponent implements OnInit, OnDestroy {
    selectedAsset$ = this.store.select(selectSelectedAsset);
    swot$ = this.store.select(selectAssetSwot);
    assetSwotEditable$ = this.store.select(selectAssetSwotEdit);
    isEditDisabled$ = this.store.select(selectIsAnyAssetFormInEditMode);
    gptSwot$ = this.store.select(selectGeneratedSwotAnalysis);
    isGptEditMode = false;
    gptErrorMessage = "";
    subscriptions: Subscription[] = [];

    mostRecentSwotModDate?: string;

    swotForm = this.formBuilder.group({
        swotStrengths: this.formBuilder.control("", {nonNullable: true}),
        swotWeaknesses: this.formBuilder.control("", {nonNullable: true}),
        swotOpportunities: this.formBuilder.control("", {nonNullable: true}),
        swotThreats: this.formBuilder.control("", {nonNullable: true}),
    });

    constructor(private readonly store: Store, private readonly formBuilder: FormBuilder, public dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.store.select(selectAssetSwot).pipe(map((swot: Swot) => {
                const swotTransformed = {
                    swotStrengths: swot.swotStrengths.text,
                    swotWeaknesses: swot.swotWeaknesses.text,
                    swotOpportunities: swot.swotOpportunities.text,
                    swotThreats: swot.swotThreats.text
                };
                this.swotForm.patchValue(swotTransformed);

                this.calculateMostRecentSwotModDate(swot);
            })).subscribe());

        this.subscriptions.push(
            this.store.select(selectAssetSwotEdit).pipe(map((isEditable) =>
                isEditable ? this.swotForm.enable() : this.swotForm.disable())
            ).subscribe()
        );

        this.subscriptions.push(
            this.store.select(selectGptSwotIsEditable).pipe(map(isGptEditable => {
                this.isGptEditMode = isGptEditable;
            })).subscribe()
        );

        this.subscriptions.push(
            this.gptSwot$.pipe(map(gptSwot => {
                if (gptSwot.status === "ERROR") {
                    this.gptErrorMessage = gptSwot.errorMessage;
                    this.isGptEditMode = false;
                }
            })).subscribe()
        );
    }

    private calculateMostRecentSwotModDate(swot: Swot): void {
        this.mostRecentSwotModDate = DateUtil.toIsoDate(DateUtil.mostRecent(
            DateUtil.fromIsoTimestamp(swot.swotStrengths.modDate),
            DateUtil.fromIsoTimestamp(swot.swotWeaknesses.modDate),
            DateUtil.fromIsoTimestamp(swot.swotOpportunities.modDate),
            DateUtil.fromIsoTimestamp(swot.swotThreats.modDate))
        );
    }

    editMode = () => this.store.dispatch(AssetSwotActions.edit());

    save() {
        if (this.swotForm.valid) {
            this.store.dispatch(AssetSwotActions.save(this.swotForm.getRawValue()));
        }
    }

    cancel = () => this.store.dispatch(AssetSwotActions.cancel());

    gptEditMode = () => this.store.dispatch(AssetSwotActions.gptedit());

    gptCancel = () => this.store.dispatch(AssetSwotActions.gptcancel());

    openConfirmDialog(copyOrAddGptText: () => void) {
        const dialogRef = this.dialog.open(ConfirmationComponent);
        dialogRef.componentInstance.confirmMessage = `GPT generated text can be factually wrong, please check important information.`;

        dialogRef.afterClosed().pipe(map(result => {
            if (result) {
                copyOrAddGptText();
            }
        })).subscribe();
    }

    copyGptText(part: keyof Swot, swot: Swot) {
        this.openConfirmDialog(() => {
            const text = this.getSwotText(part, swot);
            if (text) {
                const update = {[part]: text};
                this.swotForm.patchValue(update);
            }
        });
    }

    addGptText(part: keyof Swot, swot: Swot) {
        this.openConfirmDialog(() => {
            const text = this.getSwotText(part, swot);
            if (text) {
                const currentValue = this.swotForm.get(part)?.value ?? "";
                const update = {[part]: `${currentValue}\n\n${text}`};
                this.swotForm.patchValue(update);
            }
        });
    }

    getSwotText(part: keyof Swot, swot: Swot) {
        return swot[part]?.text;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}

<div class="key-value-pair">
    <div class="key-value-item">{{label}}</div>
    <ng-container *ngIf="isDataset">
        <div class="multiselect-dataset">
            <ng-container *ngFor="let baselineCode of comparativeCodes">
                <mat-chip (click)="overwriteValue(baselineCode)" [ngClass]="getChipColor(baselineCode)">
                    {{baselineCode.code | codeFormat: codeTable | async}}
                </mat-chip>
            </ng-container>
        </div>
    </ng-container>
    <div *ngIf="!isEditable" class="key-value-item">
        <ng-container *ngFor="let code of codes">
            <div>{{code.code | codeFormat: codeTable | async}}</div>
        </ng-container>
    </div>
    <div *ngIf="isEditable" class="key-value-item">
        <select *ngIf="(selectCodes$ | async) as allCodes" [formControl]="control" multiple size='{{allCodes.length}}'>
            <option *ngFor="let code of allCodes" [value]="code.code">{{ code.translations?.["en"] }}</option>
        </select>
    </div>
</div>

import {AssetValuation} from "./asset-valuation";
import {AssetValuationScenario} from "./asset-valuation-scenario";
import {TraceableDate} from "../../../shared/model/traceable";
import {FundInvestmentData} from "../../generated-sources/api";

export type ValuationInput = "CF" | "MULTIPLE";
export type GpQuality = "LOW" | "MEDIUM" | "HIGH";

export interface DiscountMultipleValuation {
    assetValuation: AssetValuation;
    scenarios: Array<AssetValuationScenario>;
    closingDate: TraceableDate;
    fundInvestmentData: FundInvestmentData;
    valuationInput: ValuationInput;
    gpQuality?: GpQuality;
}

export const isValidValuationInput = (value: string): value is ValuationInput => ["CF", "MULTIPLE"].includes(value);
export const isGpQuality = (value?: string): value is GpQuality => !!value && ["LOW", "MEDIUM", "HIGH"].includes(value);


import {Code} from "../model/code";

export class CodeUtil {
    static sortBySortIndex(codes: Code[], ascending = true): Code[] {
        if (codes.some(code => code.parameters?.["sortIndex"] !== null && code.parameters?.["sortIndex"] !== undefined)) {
            return codes.sort((code1, code2) => {
                const value1 = this.getSortIndexValue(code1.parameters, ascending);
                const value2 = this.getSortIndexValue(code2.parameters, ascending);

                const result = ascending ? value1 - value2 : value2 - value1;
                if (!result) {
                    const en1 = code1.translations?.["en"] ?? "";
                    const en2 = code2.translations?.["en"] ?? "";
                    return en1.localeCompare(en2);
                }
                return result;
            });
        }
        return this.sortByTranslations(codes);
    }

    static sortByTranslations(translatedCodes: Code[]): Code[] {
        return translatedCodes.sort((code1, code2) => {
            const en1 = code1.translations?.["en"] ?? "";
            const en2 = code2.translations?.["en"] ?? "";
            return en1.localeCompare(en2);
        });
    }

    static getSortIndexValue(parameters: { [parameterName: string]: string } = {}, ascending = true): number {
        const sortIndex = +parameters["sortIndex"];
        if (ascending) {
            return isNaN(sortIndex) ? Number.MAX_VALUE : sortIndex;
        } else {
            return isNaN(sortIndex) ? -Number.MAX_VALUE : sortIndex;
        }
    }
}

import {Component, OnInit} from "@angular/core";
import {combineLatest, map, Observable, startWith} from "rxjs";
import {noWhitespaceValidator, numberNotBiggerThan190000m} from "../../../shared/utils/form-validators";
import {Store} from "@ngrx/store";
import {FormBuilder, Validators} from "@angular/forms";
import {DecimalFormatPipe} from "../../../shared/pipes/decimal-format/decimal-format.pipe";
import {selectDealDetails, selectIsAnyDealFormInEditMode} from "../../store/deal/deal.selectors";
import {DealDetailsAction} from "../../store/deal.actions";
import {Deal} from "../../models/deal";
import {selectCodesFromTable} from "../../../shared/store/shared.selectors";
import {Code, CodeTableEnum} from "../../../shared/model/code";
import {selectSelectedSeller} from "../../store/seller/seller.selectors";

@Component({
    selector: "valumize-deal-details",
    templateUrl: "./deal-details.component.html"
})
export class DealDetailsComponent implements OnInit {

    codeTableCurrency = CodeTableEnum.SHARED_CURRENCY;
    codeTableProcessType = CodeTableEnum.PROCESS_TYPE;

    dealDetails$ = this.store.select(selectDealDetails);
    selectCodesCurrency$ = this.store.select(selectCodesFromTable(this.codeTableCurrency));
    filteredCurrencies$: Observable<Code[]> | undefined;
    selectCodesProcessType$ = this.store.select(selectCodesFromTable(this.codeTableProcessType));
    seller$ = this.store.select(selectSelectedSeller);
    isEditDisabled$ = this.store.select(selectIsAnyDealFormInEditMode);

    dealDetailsForm = this.formBuilder.group({
        secondaryCarry: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]}),
        secondaryHurdle: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]}),
        currencyIso: this.formBuilder.control<string>("", {nonNullable: true, validators: [Validators.required, noWhitespaceValidator()]}),
        source: this.formBuilder.control<string>("", {validators: Validators.maxLength(100)}),
        processType: this.formBuilder.control<string>("", {nonNullable: true}),
        dealCaptain: this.formBuilder.control<string>("", {validators: Validators.maxLength(100)})
    });

    constructor(private readonly store: Store,
                private readonly formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.store.select(selectDealDetails).pipe(
            map(dealDetails => {
                const deal = dealDetails.deal;
                this.dealDetailsForm.patchValue({
                    secondaryCarry: DecimalFormatPipe.transformFractionToPercent(deal.secondaryCarry.fraction ?? undefined),
                    secondaryHurdle: DecimalFormatPipe.transformFractionToPercent(deal.secondaryHurdle.fraction ?? undefined),
                    currencyIso: deal.currencyIso.code,
                    source: deal.source.text,
                    processType: deal.processType.code,
                    dealCaptain: deal.dealCaptain.text
                });
            })
        ).subscribe();

        this.filteredCurrencies$ = combineLatest([
            this.dealDetailsForm.controls.currencyIso.valueChanges.pipe(startWith("")),
            this.selectCodesCurrency$
        ]).pipe(
            map(([value, currencies]) => value
                ? currencies.filter(currency => currency.translations?.["en"].toLowerCase().includes(value.toLowerCase()))
                : currencies
            )
        );
    }

    edit = () => this.store.dispatch(DealDetailsAction.edit());

    save(deal: Deal): void {
        if (!deal) {
            return;
        }
        const formValuesToSave = Object.assign({}, this.dealDetailsForm.getRawValue());
        this.store.dispatch(DealDetailsAction.save({deal, ...formValuesToSave}));
    }

    cancel = () => this.store.dispatch(DealDetailsAction.cancel());
}

import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {FundApiService} from "../generated-sources/api";
import {GeneralPartner} from "../models/general-partner";

@Injectable({
    providedIn: "root"
})
export class GeneralPartnerService {

    constructor(private readonly fundApiService: FundApiService) {
    }

    getGeneralPartners(): Observable<GeneralPartner[]> {
        return this.fundApiService.getGeneralPartners();
    }

    getGeneralPartner(id: number): Observable<GeneralPartner> {
        return this.fundApiService.getGeneralPartner(id);
    }

    saveGeneralPartner(generalPartner: GeneralPartner) {
        return generalPartner.id ? this.fundApiService.updateGeneralPartner(generalPartner.id, generalPartner) : this.fundApiService.createGeneralPartner(generalPartner);
    }
}

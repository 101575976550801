/**
 * valumize API for managing funds
 * Lorem ipsum ...
 *
 * The version of the OpenAPI document: 0.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AggregatedFundCashflows } from '../model/aggregated-fund-cashflows';
// @ts-ignore
import { CalculateGoalSeekReturnSummaryRequest } from '../model/calculate-goal-seek-return-summary-request';
// @ts-ignore
import { Fund } from '../model/fund';
// @ts-ignore
import { FundInvestment } from '../model/fund-investment';
// @ts-ignore
import { FundReport } from '../model/fund-report';
// @ts-ignore
import { FundReturnSummary } from '../model/fund-return-summary';
// @ts-ignore
import { FundValuation } from '../model/fund-valuation';
// @ts-ignore
import { FundValuationScenario } from '../model/fund-valuation-scenario';
// @ts-ignore
import { FundWithGeneralPartner } from '../model/fund-with-general-partner';
// @ts-ignore
import { GeneralPartner } from '../model/general-partner';
// @ts-ignore
import { GetAggregatedFundCashflows409Response } from '../model/get-aggregated-fund-cashflows409-response';
// @ts-ignore
import { PartnershipInvestment } from '../model/partnership-investment';
// @ts-ignore
import { TraceableMoney } from '../model/traceable-money';
// @ts-ignore
import { UndrawnValuation } from '../model/undrawn-valuation';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class FundApiService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Calculates a fund investment
     * Calculates and returns the fund investment
     * @param fundId The id of the fund
     * @param fundReportId The id of the fund report
     * @param fundInvestmentId The id of the fund valuation
     * @param fundInvestment 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public calculateFundInvestment(fundId: number, fundReportId: number, fundInvestmentId: number, fundInvestment: FundInvestment, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FundInvestment>;
    public calculateFundInvestment(fundId: number, fundReportId: number, fundInvestmentId: number, fundInvestment: FundInvestment, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FundInvestment>>;
    public calculateFundInvestment(fundId: number, fundReportId: number, fundInvestmentId: number, fundInvestment: FundInvestment, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FundInvestment>>;
    public calculateFundInvestment(fundId: number, fundReportId: number, fundInvestmentId: number, fundInvestment: FundInvestment, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling calculateFundInvestment.');
        }
        if (fundReportId === null || fundReportId === undefined) {
            throw new Error('Required parameter fundReportId was null or undefined when calling calculateFundInvestment.');
        }
        if (fundInvestmentId === null || fundInvestmentId === undefined) {
            throw new Error('Required parameter fundInvestmentId was null or undefined when calling calculateFundInvestment.');
        }
        if (fundInvestment === null || fundInvestment === undefined) {
            throw new Error('Required parameter fundInvestment was null or undefined when calling calculateFundInvestment.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/reports/${this.configuration.encodeParam({name: "fundReportId", value: fundReportId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/investments/${this.configuration.encodeParam({name: "fundInvestmentId", value: fundInvestmentId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/calc/GeneralPartnerValuation`;
        return this.httpClient.request<FundInvestment>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: fundInvestment,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Calculates the values for the return summary
     * Calculates the values for the the return summary with a given fund valuation and its scenarios
     * @param fundId The id of the fund
     * @param fundValuationId The id of the fund valuation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public calculateFundReturnSummary(fundId: number, fundValuationId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FundReturnSummary>;
    public calculateFundReturnSummary(fundId: number, fundValuationId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FundReturnSummary>>;
    public calculateFundReturnSummary(fundId: number, fundValuationId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FundReturnSummary>>;
    public calculateFundReturnSummary(fundId: number, fundValuationId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling calculateFundReturnSummary.');
        }
        if (fundValuationId === null || fundValuationId === undefined) {
            throw new Error('Required parameter fundValuationId was null or undefined when calling calculateFundReturnSummary.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/valuations/${this.configuration.encodeParam({name: "fundValuationId", value: fundValuationId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/calc/returnSummary`;
        return this.httpClient.request<FundReturnSummary>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Calculates the bid price adjustment and return summary based on target Net Performance TVPI
     * Calculates the bid price adjustment needed to achieve a target Net Performance TVPI (base case) and then recalculates the full return summary based on this adjustment.
     * @param fundId The id of the fund
     * @param fundValuationId The id of the fund valuation
     * @param calculateGoalSeekReturnSummaryRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public calculateGoalSeekReturnSummary(fundId: number, fundValuationId: number, calculateGoalSeekReturnSummaryRequest: CalculateGoalSeekReturnSummaryRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<TraceableMoney>;
    public calculateGoalSeekReturnSummary(fundId: number, fundValuationId: number, calculateGoalSeekReturnSummaryRequest: CalculateGoalSeekReturnSummaryRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<TraceableMoney>>;
    public calculateGoalSeekReturnSummary(fundId: number, fundValuationId: number, calculateGoalSeekReturnSummaryRequest: CalculateGoalSeekReturnSummaryRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<TraceableMoney>>;
    public calculateGoalSeekReturnSummary(fundId: number, fundValuationId: number, calculateGoalSeekReturnSummaryRequest: CalculateGoalSeekReturnSummaryRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling calculateGoalSeekReturnSummary.');
        }
        if (fundValuationId === null || fundValuationId === undefined) {
            throw new Error('Required parameter fundValuationId was null or undefined when calling calculateGoalSeekReturnSummary.');
        }
        if (calculateGoalSeekReturnSummaryRequest === null || calculateGoalSeekReturnSummaryRequest === undefined) {
            throw new Error('Required parameter calculateGoalSeekReturnSummaryRequest was null or undefined when calling calculateGoalSeekReturnSummary.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/valuations/${this.configuration.encodeParam({name: "fundValuationId", value: fundValuationId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/calc/goalSeekReturnSummary`;
        return this.httpClient.request<TraceableMoney>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: calculateGoalSeekReturnSummaryRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * calculates the total Undrawn
     * Calculates the Total Undrawn with a given Fund Report
     * @param fundId The id of the fund
     * @param fundReportId The id of the fund report
     * @param fundReport 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public calculateTotalUndrawn(fundId: number, fundReportId: number, fundReport: FundReport, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FundReport>;
    public calculateTotalUndrawn(fundId: number, fundReportId: number, fundReport: FundReport, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FundReport>>;
    public calculateTotalUndrawn(fundId: number, fundReportId: number, fundReport: FundReport, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FundReport>>;
    public calculateTotalUndrawn(fundId: number, fundReportId: number, fundReport: FundReport, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling calculateTotalUndrawn.');
        }
        if (fundReportId === null || fundReportId === undefined) {
            throw new Error('Required parameter fundReportId was null or undefined when calling calculateTotalUndrawn.');
        }
        if (fundReport === null || fundReport === undefined) {
            throw new Error('Required parameter fundReport was null or undefined when calling calculateTotalUndrawn.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/reports/${this.configuration.encodeParam({name: "fundReportId", value: fundReportId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/calc/totalUndrawn`;
        return this.httpClient.request<FundReport>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: fundReport,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Calculates the values for the undrawn valuation
     * Calculates the values for the the undrawn valuation with a given fund valuation and its scenarios
     * @param fundId The id of the fund
     * @param fundValuationId The id of the fund valuation
     * @param undrawnValuation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public calculateUndrawnValuation(fundId: number, fundValuationId: number, undrawnValuation: UndrawnValuation, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<UndrawnValuation>;
    public calculateUndrawnValuation(fundId: number, fundValuationId: number, undrawnValuation: UndrawnValuation, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<UndrawnValuation>>;
    public calculateUndrawnValuation(fundId: number, fundValuationId: number, undrawnValuation: UndrawnValuation, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<UndrawnValuation>>;
    public calculateUndrawnValuation(fundId: number, fundValuationId: number, undrawnValuation: UndrawnValuation, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling calculateUndrawnValuation.');
        }
        if (fundValuationId === null || fundValuationId === undefined) {
            throw new Error('Required parameter fundValuationId was null or undefined when calling calculateUndrawnValuation.');
        }
        if (undrawnValuation === null || undrawnValuation === undefined) {
            throw new Error('Required parameter undrawnValuation was null or undefined when calling calculateUndrawnValuation.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/valuations/${this.configuration.encodeParam({name: "fundValuationId", value: fundValuationId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/calc/undrawnValuation`;
        return this.httpClient.request<UndrawnValuation>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: undrawnValuation,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new fund
     * Creates and returns a new fund
     * @param fund 
     * @param selectedDataset Specifies a filter for the selected dataset. This filter is optional. If nothing is specified, the master dataset is selected.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createFund(fund: Fund, selectedDataset?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Fund>;
    public createFund(fund: Fund, selectedDataset?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Fund>>;
    public createFund(fund: Fund, selectedDataset?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Fund>>;
    public createFund(fund: Fund, selectedDataset?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fund === null || fund === undefined) {
            throw new Error('Required parameter fund was null or undefined when calling createFund.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (selectedDataset !== undefined && selectedDataset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>selectedDataset, 'selectedDataset');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds`;
        return this.httpClient.request<Fund>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: fund,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new fund report
     * Creates and returns a new fund report
     * @param fundId The id of the fund
     * @param fundReport 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createFundReport(fundId: number, fundReport: FundReport, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FundReport>;
    public createFundReport(fundId: number, fundReport: FundReport, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FundReport>>;
    public createFundReport(fundId: number, fundReport: FundReport, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FundReport>>;
    public createFundReport(fundId: number, fundReport: FundReport, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling createFundReport.');
        }
        if (fundReport === null || fundReport === undefined) {
            throw new Error('Required parameter fundReport was null or undefined when calling createFundReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/reports`;
        return this.httpClient.request<FundReport>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: fundReport,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new fund valuation
     * Creates and returns a new fund valuation
     * @param fundId The id of the fund
     * @param fundValuation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createFundValuation(fundId: number, fundValuation: FundValuation, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FundValuation>;
    public createFundValuation(fundId: number, fundValuation: FundValuation, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FundValuation>>;
    public createFundValuation(fundId: number, fundValuation: FundValuation, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FundValuation>>;
    public createFundValuation(fundId: number, fundValuation: FundValuation, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling createFundValuation.');
        }
        if (fundValuation === null || fundValuation === undefined) {
            throw new Error('Required parameter fundValuation was null or undefined when calling createFundValuation.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/valuations`;
        return this.httpClient.request<FundValuation>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: fundValuation,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new fund valuation scenario
     * Creates and returns a new fund valuation scenario
     * @param fundId The id of the fund
     * @param fundValuationId The id of the fund valuation
     * @param fundValuationScenario 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createFundValuationScenario(fundId: number, fundValuationId: number, fundValuationScenario: FundValuationScenario, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FundValuationScenario>;
    public createFundValuationScenario(fundId: number, fundValuationId: number, fundValuationScenario: FundValuationScenario, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FundValuationScenario>>;
    public createFundValuationScenario(fundId: number, fundValuationId: number, fundValuationScenario: FundValuationScenario, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FundValuationScenario>>;
    public createFundValuationScenario(fundId: number, fundValuationId: number, fundValuationScenario: FundValuationScenario, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling createFundValuationScenario.');
        }
        if (fundValuationId === null || fundValuationId === undefined) {
            throw new Error('Required parameter fundValuationId was null or undefined when calling createFundValuationScenario.');
        }
        if (fundValuationScenario === null || fundValuationScenario === undefined) {
            throw new Error('Required parameter fundValuationScenario was null or undefined when calling createFundValuationScenario.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/valuations/${this.configuration.encodeParam({name: "fundValuationId", value: fundValuationId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/scenario`;
        return this.httpClient.request<FundValuationScenario>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: fundValuationScenario,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a general partner
     * Creates the general partner
     * @param generalPartner 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createGeneralPartner(generalPartner: GeneralPartner, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GeneralPartner>;
    public createGeneralPartner(generalPartner: GeneralPartner, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralPartner>>;
    public createGeneralPartner(generalPartner: GeneralPartner, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralPartner>>;
    public createGeneralPartner(generalPartner: GeneralPartner, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (generalPartner === null || generalPartner === undefined) {
            throw new Error('Required parameter generalPartner was null or undefined when calling createGeneralPartner.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/partners`;
        return this.httpClient.request<GeneralPartner>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: generalPartner,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the cashflows for the scenario, aggregated by date.
     * Returns the cashflows for the selected fund valuation scenario, aggregated by date. Each aggregated cashflow item also contains the original cashflows.
     * @param fundId The id of the fund
     * @param fundValuationId The id of the fund valuation
     * @param scenarioId The id of the fund valuation\&#39;s scenario
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAggregatedFundCashflows(fundId: number, fundValuationId: number, scenarioId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AggregatedFundCashflows>;
    public getAggregatedFundCashflows(fundId: number, fundValuationId: number, scenarioId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AggregatedFundCashflows>>;
    public getAggregatedFundCashflows(fundId: number, fundValuationId: number, scenarioId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AggregatedFundCashflows>>;
    public getAggregatedFundCashflows(fundId: number, fundValuationId: number, scenarioId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling getAggregatedFundCashflows.');
        }
        if (fundValuationId === null || fundValuationId === undefined) {
            throw new Error('Required parameter fundValuationId was null or undefined when calling getAggregatedFundCashflows.');
        }
        if (scenarioId === null || scenarioId === undefined) {
            throw new Error('Required parameter scenarioId was null or undefined when calling getAggregatedFundCashflows.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/valuations/${this.configuration.encodeParam({name: "fundValuationId", value: fundValuationId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/scenario/${this.configuration.encodeParam({name: "scenarioId", value: scenarioId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/aggregated_cashflows`;
        return this.httpClient.request<AggregatedFundCashflows>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the fund with the fundId
     * Gets the fund with the fundId
     * @param fundId The id of the fund
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFund(fundId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FundWithGeneralPartner>;
    public getFund(fundId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FundWithGeneralPartner>>;
    public getFund(fundId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FundWithGeneralPartner>>;
    public getFund(fundId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling getFund.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<FundWithGeneralPartner>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the fund investment with the fundInvestmentId
     * Gets the fund investment of a fund report with the fundId, fundReportId and the fundInvestmentId
     * @param fundId The id of the fund
     * @param fundReportId The id of the fund report
     * @param fundInvestmentId The id of the fund investment
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFundInvestment(fundId: number, fundReportId: number, fundInvestmentId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FundInvestment>;
    public getFundInvestment(fundId: number, fundReportId: number, fundInvestmentId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FundInvestment>>;
    public getFundInvestment(fundId: number, fundReportId: number, fundInvestmentId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FundInvestment>>;
    public getFundInvestment(fundId: number, fundReportId: number, fundInvestmentId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling getFundInvestment.');
        }
        if (fundReportId === null || fundReportId === undefined) {
            throw new Error('Required parameter fundReportId was null or undefined when calling getFundInvestment.');
        }
        if (fundInvestmentId === null || fundInvestmentId === undefined) {
            throw new Error('Required parameter fundInvestmentId was null or undefined when calling getFundInvestment.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/reports/${this.configuration.encodeParam({name: "fundReportId", value: fundReportId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/investments/${this.configuration.encodeParam({name: "fundInvestmentId", value: fundInvestmentId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<FundInvestment>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the fund report with the fundReportId
     * Gets the fund report of a fund with the fundId and fundReportId
     * @param fundId The id of the fund
     * @param fundReportId The id of the fund report
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFundReport(fundId: number, fundReportId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FundReport>;
    public getFundReport(fundId: number, fundReportId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FundReport>>;
    public getFundReport(fundId: number, fundReportId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FundReport>>;
    public getFundReport(fundId: number, fundReportId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling getFundReport.');
        }
        if (fundReportId === null || fundReportId === undefined) {
            throw new Error('Required parameter fundReportId was null or undefined when calling getFundReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/reports/${this.configuration.encodeParam({name: "fundReportId", value: fundReportId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<FundReport>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the fund valuation with the fundValuationId
     * Gets the fund valuation of a fund with the fundId and fundValuationId
     * @param fundId The id of the fund
     * @param fundValuationId The id of the fund valuation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFundValuation(fundId: number, fundValuationId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FundValuation>;
    public getFundValuation(fundId: number, fundValuationId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FundValuation>>;
    public getFundValuation(fundId: number, fundValuationId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FundValuation>>;
    public getFundValuation(fundId: number, fundValuationId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling getFundValuation.');
        }
        if (fundValuationId === null || fundValuationId === undefined) {
            throw new Error('Required parameter fundValuationId was null or undefined when calling getFundValuation.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/valuations/${this.configuration.encodeParam({name: "fundValuationId", value: fundValuationId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<FundValuation>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the scenario with the scenarioId
     * Gets the scenario of a fund valuation with the fundId, fundValuationId and scenarioId
     * @param fundId The id of the fund
     * @param fundValuationId The id of the fund valuation
     * @param scenarioId The id of the fund valuation\&#39;s scenario
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFundValuationScenario(fundId: number, fundValuationId: number, scenarioId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FundValuationScenario>;
    public getFundValuationScenario(fundId: number, fundValuationId: number, scenarioId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FundValuationScenario>>;
    public getFundValuationScenario(fundId: number, fundValuationId: number, scenarioId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FundValuationScenario>>;
    public getFundValuationScenario(fundId: number, fundValuationId: number, scenarioId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling getFundValuationScenario.');
        }
        if (fundValuationId === null || fundValuationId === undefined) {
            throw new Error('Required parameter fundValuationId was null or undefined when calling getFundValuationScenario.');
        }
        if (scenarioId === null || scenarioId === undefined) {
            throw new Error('Required parameter scenarioId was null or undefined when calling getFundValuationScenario.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/valuations/${this.configuration.encodeParam({name: "fundValuationId", value: fundValuationId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/scenario/${this.configuration.encodeParam({name: "scenarioId", value: scenarioId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<FundValuationScenario>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of funds
     * Gets all funds
     * @param selectedDataset Specifies a filter for the selected dataset. This filter is optional. If nothing is specified, the master dataset is selected.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunds(selectedDataset?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Fund>>;
    public getFunds(selectedDataset?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Fund>>>;
    public getFunds(selectedDataset?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Fund>>>;
    public getFunds(selectedDataset?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (selectedDataset !== undefined && selectedDataset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>selectedDataset, 'selectedDataset');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds`;
        return this.httpClient.request<Array<Fund>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the general partner with the partnerId
     * Gets the general partner with the partnerId
     * @param partnerId The id of the general partner
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGeneralPartner(partnerId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GeneralPartner>;
    public getGeneralPartner(partnerId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralPartner>>;
    public getGeneralPartner(partnerId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralPartner>>;
    public getGeneralPartner(partnerId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling getGeneralPartner.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/partners/${this.configuration.encodeParam({name: "partnerId", value: partnerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<GeneralPartner>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the general partner for the fund
     * Gets the general partner for the fund
     * @param fundId The id of the fund
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGeneralPartnerForFund(fundId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GeneralPartner>;
    public getGeneralPartnerForFund(fundId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralPartner>>;
    public getGeneralPartnerForFund(fundId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralPartner>>;
    public getGeneralPartnerForFund(fundId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling getGeneralPartnerForFund.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/partner`;
        return this.httpClient.request<GeneralPartner>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of general partners
     * Gets all general partners
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGeneralPartners(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<GeneralPartner>>;
    public getGeneralPartners(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<GeneralPartner>>>;
    public getGeneralPartners(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<GeneralPartner>>>;
    public getGeneralPartners(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/partners`;
        return this.httpClient.request<Array<GeneralPartner>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of fund investments
     * Gets all fund investments that belong to a fund report
     * @param fundId The id of the fund
     * @param fundReportId The id of the fund report
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInvestmentsForFundReport(fundId: number, fundReportId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<FundInvestment>>;
    public getInvestmentsForFundReport(fundId: number, fundReportId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<FundInvestment>>>;
    public getInvestmentsForFundReport(fundId: number, fundReportId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<FundInvestment>>>;
    public getInvestmentsForFundReport(fundId: number, fundReportId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling getInvestmentsForFundReport.');
        }
        if (fundReportId === null || fundReportId === undefined) {
            throw new Error('Required parameter fundReportId was null or undefined when calling getInvestmentsForFundReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/reports/${this.configuration.encodeParam({name: "fundReportId", value: fundReportId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/investments`;
        return this.httpClient.request<Array<FundInvestment>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of partnership investments for the fund
     * Gets all partnership investments for the fund
     * @param fundId The id of the fund
     * @param fundReportId The id of the fund report
     * @param fundValuationId The id of the fund valuation if it exists
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPartnershipInvestments(fundId: number, fundReportId: number, fundValuationId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PartnershipInvestment>;
    public getPartnershipInvestments(fundId: number, fundReportId: number, fundValuationId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PartnershipInvestment>>;
    public getPartnershipInvestments(fundId: number, fundReportId: number, fundValuationId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PartnershipInvestment>>;
    public getPartnershipInvestments(fundId: number, fundReportId: number, fundValuationId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling getPartnershipInvestments.');
        }
        if (fundReportId === null || fundReportId === undefined) {
            throw new Error('Required parameter fundReportId was null or undefined when calling getPartnershipInvestments.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (fundValuationId !== undefined && fundValuationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fundValuationId, 'fundValuationId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/reports/${this.configuration.encodeParam({name: "fundReportId", value: fundReportId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/partnershipInvestments`;
        return this.httpClient.request<PartnershipInvestment>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of fund reports
     * Gets all fund reports that belong to a fund
     * @param fundId The id of the fund
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReportsForFund(fundId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<FundReport>>;
    public getReportsForFund(fundId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<FundReport>>>;
    public getReportsForFund(fundId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<FundReport>>>;
    public getReportsForFund(fundId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling getReportsForFund.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/reports`;
        return this.httpClient.request<Array<FundReport>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of funds valuation scenarios
     * Gets all scenarios that belong to an fund valuation
     * @param fundId The id of the fund
     * @param fundValuationId The id of the fund valuation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getScenariosForFundValuation(fundId: number, fundValuationId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<FundValuationScenario>>;
    public getScenariosForFundValuation(fundId: number, fundValuationId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<FundValuationScenario>>>;
    public getScenariosForFundValuation(fundId: number, fundValuationId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<FundValuationScenario>>>;
    public getScenariosForFundValuation(fundId: number, fundValuationId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling getScenariosForFundValuation.');
        }
        if (fundValuationId === null || fundValuationId === undefined) {
            throw new Error('Required parameter fundValuationId was null or undefined when calling getScenariosForFundValuation.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/valuations/${this.configuration.encodeParam({name: "fundValuationId", value: fundValuationId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/scenario`;
        return this.httpClient.request<Array<FundValuationScenario>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of fund valuations
     * Gets all fund valuations that belong to a fund
     * @param fundId The id of the fund
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getValuationsForFund(fundId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<FundValuation>>;
    public getValuationsForFund(fundId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<FundValuation>>>;
    public getValuationsForFund(fundId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<FundValuation>>>;
    public getValuationsForFund(fundId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling getValuationsForFund.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/valuations`;
        return this.httpClient.request<Array<FundValuation>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an fund
     * Updates and returns the fund
     * @param fundId The id of the fund
     * @param fund 
     * @param generalPartnerId The id of the general partner
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFund(fundId: number, fund: Fund, generalPartnerId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Fund>;
    public updateFund(fundId: number, fund: Fund, generalPartnerId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Fund>>;
    public updateFund(fundId: number, fund: Fund, generalPartnerId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Fund>>;
    public updateFund(fundId: number, fund: Fund, generalPartnerId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling updateFund.');
        }
        if (fund === null || fund === undefined) {
            throw new Error('Required parameter fund was null or undefined when calling updateFund.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (generalPartnerId !== undefined && generalPartnerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>generalPartnerId, 'generalPartnerId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<Fund>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: fund,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a fund investment
     * Updates and returns the fund investment
     * @param fundId The id of the fund
     * @param fundReportId The id of the fund report
     * @param fundInvestmentId The id of the fund investment
     * @param fundInvestment 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFundInvestment(fundId: number, fundReportId: number, fundInvestmentId: number, fundInvestment: FundInvestment, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FundInvestment>;
    public updateFundInvestment(fundId: number, fundReportId: number, fundInvestmentId: number, fundInvestment: FundInvestment, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FundInvestment>>;
    public updateFundInvestment(fundId: number, fundReportId: number, fundInvestmentId: number, fundInvestment: FundInvestment, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FundInvestment>>;
    public updateFundInvestment(fundId: number, fundReportId: number, fundInvestmentId: number, fundInvestment: FundInvestment, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling updateFundInvestment.');
        }
        if (fundReportId === null || fundReportId === undefined) {
            throw new Error('Required parameter fundReportId was null or undefined when calling updateFundInvestment.');
        }
        if (fundInvestmentId === null || fundInvestmentId === undefined) {
            throw new Error('Required parameter fundInvestmentId was null or undefined when calling updateFundInvestment.');
        }
        if (fundInvestment === null || fundInvestment === undefined) {
            throw new Error('Required parameter fundInvestment was null or undefined when calling updateFundInvestment.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/reports/${this.configuration.encodeParam({name: "fundReportId", value: fundReportId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/investments/${this.configuration.encodeParam({name: "fundInvestmentId", value: fundInvestmentId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<FundInvestment>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: fundInvestment,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates fund investments
     * Updates and returns a list of fund investments
     * @param fundId The id of the fund
     * @param fundReportId The id of the fund report
     * @param fundInvestment 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFundInvestments(fundId: number, fundReportId: number, fundInvestment: Array<FundInvestment>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<FundInvestment>>;
    public updateFundInvestments(fundId: number, fundReportId: number, fundInvestment: Array<FundInvestment>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<FundInvestment>>>;
    public updateFundInvestments(fundId: number, fundReportId: number, fundInvestment: Array<FundInvestment>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<FundInvestment>>>;
    public updateFundInvestments(fundId: number, fundReportId: number, fundInvestment: Array<FundInvestment>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling updateFundInvestments.');
        }
        if (fundReportId === null || fundReportId === undefined) {
            throw new Error('Required parameter fundReportId was null or undefined when calling updateFundInvestments.');
        }
        if (fundInvestment === null || fundInvestment === undefined) {
            throw new Error('Required parameter fundInvestment was null or undefined when calling updateFundInvestments.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/reports/${this.configuration.encodeParam({name: "fundReportId", value: fundReportId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/investments`;
        return this.httpClient.request<Array<FundInvestment>>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: fundInvestment,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a fund report
     * Updates and returns the fund report
     * @param fundId The id of the fund
     * @param fundReportId The id of the fund report
     * @param fundReport 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFundReport(fundId: number, fundReportId: number, fundReport: FundReport, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FundReport>;
    public updateFundReport(fundId: number, fundReportId: number, fundReport: FundReport, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FundReport>>;
    public updateFundReport(fundId: number, fundReportId: number, fundReport: FundReport, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FundReport>>;
    public updateFundReport(fundId: number, fundReportId: number, fundReport: FundReport, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling updateFundReport.');
        }
        if (fundReportId === null || fundReportId === undefined) {
            throw new Error('Required parameter fundReportId was null or undefined when calling updateFundReport.');
        }
        if (fundReport === null || fundReport === undefined) {
            throw new Error('Required parameter fundReport was null or undefined when calling updateFundReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/reports/${this.configuration.encodeParam({name: "fundReportId", value: fundReportId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<FundReport>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: fundReport,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a fund valuation
     * Updates and returns the fund valuation
     * @param fundId The id of the fund
     * @param fundValuationId The id of the fund valuation
     * @param fundValuation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFundValuation(fundId: number, fundValuationId: number, fundValuation: FundValuation, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FundValuation>;
    public updateFundValuation(fundId: number, fundValuationId: number, fundValuation: FundValuation, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FundValuation>>;
    public updateFundValuation(fundId: number, fundValuationId: number, fundValuation: FundValuation, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FundValuation>>;
    public updateFundValuation(fundId: number, fundValuationId: number, fundValuation: FundValuation, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling updateFundValuation.');
        }
        if (fundValuationId === null || fundValuationId === undefined) {
            throw new Error('Required parameter fundValuationId was null or undefined when calling updateFundValuation.');
        }
        if (fundValuation === null || fundValuation === undefined) {
            throw new Error('Required parameter fundValuation was null or undefined when calling updateFundValuation.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/valuations/${this.configuration.encodeParam({name: "fundValuationId", value: fundValuationId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<FundValuation>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: fundValuation,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a scenario
     * Updates and returns the scenario
     * @param fundId The id of the fund
     * @param fundValuationId The id of the fund valuation
     * @param scenarioId The id of the scenario
     * @param fundValuationScenario 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFundValuationScenario(fundId: number, fundValuationId: number, scenarioId: number, fundValuationScenario: FundValuationScenario, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FundValuationScenario>;
    public updateFundValuationScenario(fundId: number, fundValuationId: number, scenarioId: number, fundValuationScenario: FundValuationScenario, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FundValuationScenario>>;
    public updateFundValuationScenario(fundId: number, fundValuationId: number, scenarioId: number, fundValuationScenario: FundValuationScenario, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FundValuationScenario>>;
    public updateFundValuationScenario(fundId: number, fundValuationId: number, scenarioId: number, fundValuationScenario: FundValuationScenario, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling updateFundValuationScenario.');
        }
        if (fundValuationId === null || fundValuationId === undefined) {
            throw new Error('Required parameter fundValuationId was null or undefined when calling updateFundValuationScenario.');
        }
        if (scenarioId === null || scenarioId === undefined) {
            throw new Error('Required parameter scenarioId was null or undefined when calling updateFundValuationScenario.');
        }
        if (fundValuationScenario === null || fundValuationScenario === undefined) {
            throw new Error('Required parameter fundValuationScenario was null or undefined when calling updateFundValuationScenario.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/valuations/${this.configuration.encodeParam({name: "fundValuationId", value: fundValuationId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/scenario/${this.configuration.encodeParam({name: "scenarioId", value: scenarioId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<FundValuationScenario>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: fundValuationScenario,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a general partner
     * Updates and returns the general partner
     * @param partnerId The id of the general partner
     * @param generalPartner 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateGeneralPartner(partnerId: number, generalPartner: GeneralPartner, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GeneralPartner>;
    public updateGeneralPartner(partnerId: number, generalPartner: GeneralPartner, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralPartner>>;
    public updateGeneralPartner(partnerId: number, generalPartner: GeneralPartner, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralPartner>>;
    public updateGeneralPartner(partnerId: number, generalPartner: GeneralPartner, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling updateGeneralPartner.');
        }
        if (generalPartner === null || generalPartner === undefined) {
            throw new Error('Required parameter generalPartner was null or undefined when calling updateGeneralPartner.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/partners/${this.configuration.encodeParam({name: "partnerId", value: partnerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<GeneralPartner>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: generalPartner,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the scenario array
     * Updates and returns the scenario array
     * @param fundId The id of the fund
     * @param fundValuationId The id of the fund valuation
     * @param fundValuationScenario 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateScenariosForFundValuation(fundId: number, fundValuationId: number, fundValuationScenario: Array<FundValuationScenario>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<FundValuationScenario>>;
    public updateScenariosForFundValuation(fundId: number, fundValuationId: number, fundValuationScenario: Array<FundValuationScenario>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<FundValuationScenario>>>;
    public updateScenariosForFundValuation(fundId: number, fundValuationId: number, fundValuationScenario: Array<FundValuationScenario>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<FundValuationScenario>>>;
    public updateScenariosForFundValuation(fundId: number, fundValuationId: number, fundValuationScenario: Array<FundValuationScenario>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (fundId === null || fundId === undefined) {
            throw new Error('Required parameter fundId was null or undefined when calling updateScenariosForFundValuation.');
        }
        if (fundValuationId === null || fundValuationId === undefined) {
            throw new Error('Required parameter fundValuationId was null or undefined when calling updateScenariosForFundValuation.');
        }
        if (fundValuationScenario === null || fundValuationScenario === undefined) {
            throw new Error('Required parameter fundValuationScenario was null or undefined when calling updateScenariosForFundValuation.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/funds/${this.configuration.encodeParam({name: "fundId", value: fundId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/valuations/${this.configuration.encodeParam({name: "fundValuationId", value: fundValuationId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/scenario`;
        return this.httpClient.request<Array<FundValuationScenario>>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: fundValuationScenario,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

/* eslint-disable no-underscore-dangle */
import {AfterViewInit, Component, ElementRef, OnDestroy, ViewChild} from "@angular/core";
import {Chart, ChartType, registerables, Tooltip, TooltipPositionerMap} from "chart.js";
import {TreemapController, TreemapElement} from "chartjs-chart-treemap";
import {Store} from "@ngrx/store";
import {combineLatest, Subscription} from "rxjs";
import {selectSelectedFundId, selectTreemapDataFromPartnershipInvestment} from "../../store/fund/fund.selectors";
import {DecimalFormatPipe} from "../../../shared/pipes/decimal-format/decimal-format.pipe";
import {TraceableFormatPipe} from "../../../shared/pipes/traceable-format/traceable-format.pipe";
import {Router} from "@angular/router";
import {selectSelectedFundReportId} from "../../store/fund-report/fund-report.selectors";
import {selectSelectedFundValuationId} from "../../store/fund-valuation/fund-valuation.selectors";
import Color from "colorjs.io";

@Component({
    selector: "valumize-fund-treemap-actual",
    templateUrl: "./fund-treemap-actual.component.html",
    styleUrls: ["./fund-treemap-actual.component.scss"]
})
export class FundTreemapActualComponent implements AfterViewInit, OnDestroy {

    @ViewChild("fundTreemap") fundTreemap: ElementRef | undefined;


    fundId$ = this.store.select(selectSelectedFundId);
    fundReportId$ = this.store.select(selectSelectedFundReportId);
    fundValuationId$ = this.store.select(selectSelectedFundValuationId);
    treemapDataFromPartnershipInvestment$ = this.store.select(selectTreemapDataFromPartnershipInvestment);

    subscriptions: Subscription[] = [];

    fundId?: number;
    fundReportId?: number;
    fundValuationId?: number;

    private chart: Chart | undefined;

    missingGpNAVAssets: string[] = [];
    filteredData: any[] = [];

    constructor(
        private readonly store: Store,
        private readonly decimalFormatPipe: DecimalFormatPipe,
        private readonly traceableFormatPipe: TraceableFormatPipe,
        private readonly router: Router
    ) {
    }

    ngAfterViewInit(): void {
        this.subscriptions.push(
            combineLatest([
                this.fundId$,
                this.fundReportId$,
                this.fundValuationId$,
                this.treemapDataFromPartnershipInvestment$
            ]).subscribe(([fundId, fundReportId, fundValuationId, treemapData]) => {
                this.fundId = fundId;
                this.fundReportId = fundReportId;
                this.fundValuationId = fundValuationId;
                if (this.chart) {
                    this.chart.destroy();
                }
                if (treemapData.length > 0 && !!this.fundTreemap) {
                    this.chart = this.createTreemap(this.transformData(treemapData), this.fundTreemap.nativeElement);
                }
            })
        );
    }

    transformData(data: any[]): any[] {
        this.missingGpNAVAssets = [];

        const filteredData = data.filter((item) => {
            if (item.gpNAV.amount == null) {
                this.missingGpNAVAssets.push(item.name.text);
                return false;
            }
            return true;
        });

        this.filteredData = filteredData;

        return filteredData.map((item) => ({
            assetId: item.assetId,
            fundInvestmentId: item.fundInvestmentId,
            assetValuationId: item.assetValuationId,
            company: item.name.text,
            navMultiple: this.traceableFormatPipe.transform(item.navMultiple),
            percentageOfTotalNAV: this.traceableFormatPipe.transform(item.percentageOfTotalNAV),
            gpNAV: this.traceableFormatPipe.transform(item.gpNAV),
            remainingCost: this.traceableFormatPipe.transform(item.remainingCost),
            realizedTVPI: this.traceableFormatPipe.transform(item.realizedTVPI),
            unrealizedTVPI: this.traceableFormatPipe.transform(item.unrealizedTVPI),
            discountToNAV: this.traceableFormatPipe.transform(item.discountToNAV),
            totalTVPI: this.traceableFormatPipe.transform(item.totalTVPI),
            color: this.getColorForValue(item.totalTVPI.factor)
        }));
    }

    createTreemap(data: any[], ctx: HTMLCanvasElement): Chart {
        Chart.register(
            Tooltip,
            TreemapController,
            TreemapElement,
            ...registerables
        );

        const config = {
            type: "treemap" as ChartType,
            data: {
                datasets: [
                    {
                        data: [],
                        tree: data,
                        key: "gpNAV",
                        borderWidth: 0,
                        borderRadius: 6,
                        spacing: 1,
                        backgroundColor: (item: any) => {
                            if (item.type !== "data") {
                                return "transparent";
                            }
                            return item.raw._data.color;
                        },
                        labels: {
                            align: "center",
                            display: true,
                            formatter: (item: any) => {
                                if (item.type !== "data") {
                                    return;
                                }
                                return [
                                    item.raw._data.company,
                                    item.raw._data.percentageOfTotalNAV
                                ];
                            },
                            color: ["black", "black"],
                            font: [{size: 20, weight: "bold"}, {size: 18}],
                            position: "center"
                        }
                    }
                ],
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: (context: any) => context.mode !== "click",
                        position: "average" as keyof TooltipPositionerMap,
                        callbacks: {
                            title: () => "",
                            label: (item: any) => {
                                const dataItem = item.raw;
                                const obj = dataItem._data;
                                const label = obj.company;
                                return [
                                    `${label}`,
                                    `Percentage of total NAV: ${obj.percentageOfTotalNAV}`,
                                    `GP NAV: ${obj.gpNAV}`,
                                    `Remaining Cost: ${obj.remainingCost}`,
                                    `Realized TVPI: ${obj.realizedTVPI}`,
                                    `Total TVPI: ${obj.totalTVPI}`,
                                    `Unrealized TVPI: ${obj.unrealizedTVPI}`,
                                    `Discount to NAV: ${obj.discountToNAV}`
                                ];
                            }
                        }
                    }
                }
            }
        };

        const chart = new Chart(ctx, config);

        ctx.onclick = (event: MouseEvent) => {
            const elements = chart.getElementsAtEventForMode(event, "nearest", {intersect: true}, true);
            if (elements.length) {
                const element = elements[0] as any;
                if (element && element.element && element.element.$context && element.element.$context.raw) {
                    const assetData = element.element.$context.raw._data;
                    if (assetData && assetData.assetId) {
                        this.router.navigate(["../../assets/", assetData.assetId], {
                            queryParams: {
                                fundId: this.fundId,
                                fundReportId: this.fundReportId,
                                fundValuationId: this.fundValuationId,
                                fundInvestmentId: assetData.fundInvestmentId,
                                assetValuationId: assetData.assetValuationId
                            }
                        });
                    }
                }
            }
        };
        return chart;
    }

    getColorForValue(value: number | null): string {
        if (value === null) {
            return "rgb(169, 169, 169)";
        }

        const startColor = new Color("rgb(252,165,0)");
        const midColor = new Color("rgb(227,239,255)");
        const endColor = new Color("rgb(3,102,249)");

        if (value <= 0) {
            return startColor.toString();
        } else if (value >= 3) {
            return endColor.toString();
        }

        if (value <= 1) {
            const colorScale = startColor.range(midColor, { space: "lab" });
            return colorScale(value).toString();
        } else {
            const colorScale = midColor.range(endColor, { space: "lab" });
            return colorScale((value - 1) / 2).toString();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}

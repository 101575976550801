import {Component, Input, OnInit} from "@angular/core";
import {map, Observable} from "rxjs";
import {FormBuilder, Validators} from "@angular/forms";
import {noWhitespaceValidator} from "../../shared/utils/form-validators";
import {Store} from "@ngrx/store";
import {GeneralPartner} from "../models/general-partner";
import {selectGeneralPartners} from "../store/general-partner/general-partner.selectors";
import {FundActions, GeneralPartnerActions, GeneralPartnerListActions} from "../store/fund.actions";
import {selectCodesFromTable} from "../../shared/store/shared.selectors";
import {CodeTableEnum} from "../../shared/model/code";

@Component({
    selector: "valumize-general-partner-new-dialog",
    templateUrl: "./general-partner-new-dialog.component.html",
    styleUrls: ["./general-partner-new-dialog.component.scss"]
})
export class GeneralPartnerNewDialogComponent implements OnInit {

    @Input() fundId?: number;

    columns = ["name", "quality"];
    codeTableGpQuality = CodeTableEnum.FUND_GENERALPARTNERQUALITY;

    generalPartners$ = this.store.select(selectGeneralPartners);
    filteredGeneralPartners$?: Observable<GeneralPartner[]>;
    selectCodesGpQuality$ = this.store.select(selectCodesFromTable(this.codeTableGpQuality));

    generalPartnerForm = this.formBuilder.group({
        generalPartnerName: this.formBuilder.control<string>("", {nonNullable: true, validators: [Validators.required, Validators.maxLength(100), noWhitespaceValidator()]}),
        generalPartnerQuality: this.formBuilder.control<string>("", {nonNullable: true, validators: [Validators.required, noWhitespaceValidator()]})
    });

    constructor(private readonly store: Store, private readonly formBuilder: FormBuilder) {
        this.store.dispatch(GeneralPartnerListActions.load());
    }

    ngOnInit(): void {
        this.filteredGeneralPartners$ = this.generalPartners$;
    }

    onSearch() {
        if (this.generalPartnerForm.value.generalPartnerName) {
            this.filteredGeneralPartners$ = this.generalPartners$.pipe(
                map((generalPartners) => generalPartners
                    .filter((generalPartner) => generalPartner.name.text
                        ? generalPartner.name.text.toLowerCase().includes(this.generalPartnerForm.getRawValue().generalPartnerName.toLowerCase())
                        : false
                    )));
        } else {
            this.filteredGeneralPartners$ = this.generalPartners$;
        }
    }

    createGeneralPartner() {
        if (this.generalPartnerForm.valid) {
            this.store.dispatch(GeneralPartnerActions.create({
                name: this.generalPartnerForm.getRawValue().generalPartnerName,
                quality: this.generalPartnerForm.getRawValue().generalPartnerQuality
            }));
        }
    }

    saveGeneralPartnerOnFund(generalPartnerId: number) {
        this.store.dispatch(FundActions.savegeneralpartneronfund({generalPartnerId}));
    }
}

import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {FundActions, FundNameActions, FundReportActions} from "../store/fund.actions";
import {selectBaselineFund, selectFundName, selectIsAnyFundFormInEditMode} from "../store/fund/fund.selectors";
import {map, Subscription} from "rxjs";
import {FormBuilder, Validators} from "@angular/forms";
import {EMPTY_TEXT, TraceableDate, TraceableText} from "../../shared/model/traceable";
import {MatDialog} from "@angular/material/dialog";
import {selectBaselineFundReport, selectSelectedFundReport, selectSelectedFundReportReportDate, selectSelectedFundReportsForFund} from "../store/fund-report/fund-report.selectors";
import {FundReportNewDialogComponent} from "./fund-report-new-dialog/fund-report-new-dialog.component";
import {FundValuationNewDialogComponent} from "./fund-valuation-new-dialog/fund-valuation-new-dialog.component";
import {selectSelectedFundValuation, selectSelectedFundValuationClosingDate,} from "../store/fund-valuation/fund-valuation.selectors";
import {selectSelectedDeal} from "../../deal/store/deal/deal.selectors";
import {selectSelectedSourceDataset} from "../../import/store/import.selectors";
import {FundReportMergeTargetDialogComponent} from "./fund-report-merge-target-dialog/fund-report-merge-target-dialog.component";
import {selectSelectedGeneralPartner} from "../store/general-partner/general-partner.selectors";
import {GeneralPartnerNewDialogComponent} from "../general-partner-new-dialog/general-partner-new-dialog.component";

@Component({
    selector: "valumize-fund-details-page",
    templateUrl: "./fund-details-page.component.html",
    styleUrls: ["./fund-details-page.component.scss"]
})
export class FundDetailsPageComponent implements OnInit, OnDestroy {

    fundId?: number;
    fundReportId?: number;
    fundValuationId?: number;
    dealId?: number;
    name: TraceableText = EMPTY_TEXT;
    fundReportDate?: TraceableDate;
    fundNameIsEditable = false;
    highlightFundReportDate = true;
    highlightFundClosingDate = true;
    searchEnabled = false;

    fundName$ = this.store.select(selectFundName);
    baselineFund$ = this.store.select(selectBaselineFund);
    fundReport$ = this.store.select(selectSelectedFundReport);
    baselineFundReport$ = this.store.select(selectBaselineFundReport);
    fundValuation$ = this.store.select(selectSelectedFundValuation);
    reportDate$ = this.store.select(selectSelectedFundReportReportDate);
    closingDate$ = this.store.select(selectSelectedFundValuationClosingDate);
    deal$ = this.store.select(selectSelectedDeal);
    sourceDataset$ = this.store.select(selectSelectedSourceDataset);
    isEditDisabled$ = this.store.select(selectIsAnyFundFormInEditMode);
    selectedGeneralPartner$ = this.store.select(selectSelectedGeneralPartner);

    subscriptions: Subscription[] = [];

    nameForm = this.formBuilder.group({
        fundName: this.formBuilder.control("", {nonNullable: true, validators: Validators.maxLength(100)}),
    });

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly store: Store,
        private readonly formBuilder: FormBuilder,
        public dialog: MatDialog
    ) {
        this.subscriptions.push(router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.reloadPage();
            }
        }));
    }

    ngOnInit() {
        this.fundId = parseInt(this.route.snapshot.paramMap.get("fundId") ?? "", 10);

        this.subscriptions.push(
            this.store.select(selectFundName).pipe(map((name) => {
                this.name = name.name;
                this.fundNameIsEditable = name.fundNameIsEditable;
                this.nameForm.patchValue({
                    fundName: name.name?.text
                });
                if (name.fundNameIsEditable) {
                    this.nameForm.enable();
                } else {
                    this.nameForm.disable();
                }
            })).subscribe());

        this.subscriptions.push(
            this.store.select(selectSelectedFundReportReportDate).pipe(map((fundReportDate) => {
                this.fundReportDate = fundReportDate;
            })).subscribe());
    }

    reloadPage(): void {
        /* Todo:
        * PROBLEM LOADING THE FUNDVALUATION ON THE CHECKANDLOADFUNDVALUATION FUNCTION
        * Seems that the effect is reloading the partnership investment with the rundreportid
        * but not the partnership investment with the fundvaluationid on the second try
        *
        * Problem only occurs when the fundvaluationid (and reportId) is not present on the url
        * possible solution to load the fund, the deal, the fundreport list and the fundvaluation list
        * then decide what to load next
         */
        this.fundId = parseInt(this.route.snapshot.paramMap.get("fundId") ?? "", 10);
        this.fundReportId = this.getQueryParam("fundReportId");
        this.fundValuationId = this.getQueryParam("fundValuationId");
        this.dealId = this.getQueryParam("dealId");

        this.store.dispatch(FundActions.open({
            fundId: this.fundId,
            fundReportId: this.fundReportId,
            fundValuationId: this.fundValuationId,
            dealId: this.dealId
        }));

        if (this.fundReportId) {
            this.highlightFundReportDate = true;
            setTimeout(() => this.highlightFundReportDate = false, 3000); // revert back after 3 seconds
        } else {
            this.checkAndLoadFundReport();
        }
        if (this.fundValuationId) {
            this.highlightFundClosingDate = true;
            setTimeout(() => this.highlightFundClosingDate = false, 3000); // revert back after
        }
        // TODO: #21721 Load Fund Valuation only if not in merge mode
        else if (this.fundReportId && !this.fundValuationId) {
            this.checkAndLoadFundValuation();
        }
    }

    getQueryParam(queryParamToFind: string): number | undefined {
        const queryParam = this.route.snapshot.queryParamMap.get(queryParamToFind);
        return queryParam ? parseInt(queryParam, 10) : undefined;
    }

    scrollToComponent = (componentId: string) => document.getElementById(componentId)?.scrollIntoView({behavior: "auto"});

    editMode = () => this.store.dispatch(FundNameActions.edit());

    save() {
        if (this.nameForm.valid) {
            this.store.dispatch(FundNameActions.save(this.nameForm.getRawValue()));
        }
    }

    cancel = () => this.store.dispatch(FundNameActions.cancel());

    checkAndLoadFundReport() {
        if (this.fundReportId === undefined) {
            this.subscriptions.push(this.store.select(selectSelectedFundReportsForFund).pipe(
                map((fundReports) => {
                    if (fundReports.length === 1) {
                        this.router.navigate([], {
                            relativeTo: this.route,
                            queryParams: {fundReportId: fundReports[0].id},
                            queryParamsHandling: "merge",
                            skipLocationChange: false,
                            replaceUrl: false
                        });
                    } else if (fundReports.length > 1) {
                        this.openNewFundReportDialog();
                    }
                })
            ).subscribe());
        }
    }

    openNewFundReportDialog() {
        if (this.dialog.openDialogs.length === 0) {
            const dialogRef = this.dialog.open(FundReportNewDialogComponent);
            dialogRef.componentInstance.fundId = this.fundId;
        }
    }

    checkAndLoadFundValuation() {
        if (this.fundValuationId === undefined) {

            this.openNewFundValuationDialog();
            // this.subscriptions.push(this.store.select(selectSelectedFundValuationsForFundOnReportDate).pipe(
            //     map((fundValuation) => {
            //         if (fundValuation.length === 1) {
            //             console.log("checkAndLoadFundValuation", fundValuation[0].id);
            //             this.router.navigate([], {
            //                 relativeTo: this.route,
            //                 queryParams: {fundValuationId: fundValuation[0].id},
            //                 queryParamsHandling: "merge",
            //                 skipLocationChange: false,
            //                 replaceUrl: false
            //             });
            //         } else if (fundValuation.length > 1) {
            //             this.openNewFundValuationDialog();
            //         }
            //     })
            // ).subscribe());
        }
    }

    openNewFundValuationDialog() {
        if (this.dialog.openDialogs.length === 0) {
            const dialogRef = this.dialog.open(FundValuationNewDialogComponent);
            dialogRef.componentInstance.fundId = this.fundId;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    displaySearch() {
        this.searchEnabled = !this.searchEnabled;
    }

    selectFundMergeTarget(searchResultReference: string) {
        const regex = /^\/funds\/(\d+)$/;
        const match = searchResultReference.match(regex);
        if (match) {
            const mergeTargetId = Number(match[1]);
            this.store.dispatch(FundActions.setmergetargetid({mergeTargetId}));
        }
    }

    selectFundReportMergeTarget(baselineFundId?: number) {
        const fundId = this.fundId;
        if (this.dialog.openDialogs.length === 0 && baselineFundId && fundId) {
            const dialogRef = this.dialog.open(FundReportMergeTargetDialogComponent);
            dialogRef.componentInstance.fundId = baselineFundId;
            dialogRef.componentInstance.reportDate = this.fundReportDate;

            this.subscriptions.push(
                dialogRef.componentInstance.fundReportSelected.pipe(map((mergeTargetId) => {
                    this.store.dispatch(FundReportActions.setmergetargetid({fundId, mergeTargetId}));
                })).subscribe());
        }
    }

    deselectFundMergeTarget() {
        this.store.dispatch(FundActions.setmergetargetid({mergeTargetId: undefined}));
    }

    loadFundReportMergeTarget(baselineFundId?: number, fundReportMergeTargetId?: number) { //TODO: fix manual loading of baseline fund report
        if (baselineFundId && fundReportMergeTargetId) {
            this.store.dispatch(FundReportActions.loadbaseline({fundId: baselineFundId, fundReportId: fundReportMergeTargetId}));
        }
    }

    deselectFundReportMergeTarget() {
        if (this.fundId) {
            this.store.dispatch(FundReportActions.setmergetargetid({fundId: this.fundId, mergeTargetId: undefined}));
        }
    }

    openNewGeneralPartnerDialog() {
        if (this.dialog.openDialogs.length === 0) {
            const dialogRef = this.dialog.open(GeneralPartnerNewDialogComponent);
            dialogRef.componentInstance.fundId = this.fundId;
        }
    }
}

import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl} from "@angular/forms";
import {PeriodTypeEnum} from "../../../models/asset/period-type.enum";
import {AvailablePeriodDates} from "../../../../shared/model/period";

export enum ActionType {
    Add = "ADD",
    Edit = "EDIT",
    Cancel = "CANCEL",
    Delete = "DELETE"
}

@Component({
    selector: "valumize-asset-history-period-dialog",
    templateUrl: "./asset-history-period-dialog.component.html"
})
export class AssetHistoryPeriodDialogComponent {

    action: ActionType = this.data.action;
    deletePeriod = false;
    originPeriodType = this.data.periodType;
    originPeriodKeyDate = this.data.periodKeyDate;
    periodType = new FormControl("");
    periodKeyDate = new FormControl("");
    // Array of available dates for period selection
    quarterEndDates = this.data.availablePeriodDates.QUARTER;
    semesterEndDates = this.data.availablePeriodDates.SEMESTER;
    fullFiscalYearEndDates = this.data.availablePeriodDates.FULL_FISCAL_YEAR;
    isFullFiscalYearOnly = this.data.isFullFiscalYearOnly;

    protected readonly ACTION_TYPE = ActionType;
    protected readonly PERIOD_TYPE = PeriodTypeEnum;

    constructor(
        public dialogRef: MatDialogRef<AssetHistoryPeriodDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            action: ActionType;
            periodType: string;
            periodKeyDate: string;
            originPeriodKeyDate: string;
            originPeriodType: string;
            availablePeriodDates: AvailablePeriodDates;
            isFullFiscalYearOnly: boolean;
        }
    ) {
        if (this.action === ActionType.Edit) {
            this.periodType.setValue(data.periodType);
            this.periodKeyDate.setValue(data.periodKeyDate);
        } else {
            this.periodType.setValue(PeriodTypeEnum.FullFiscalYear);
        }
    }

    onCancel(): void {
        this.dialogRef.close(ActionType.Cancel);
    }

    onSave(): void {
        this.dialogRef.close({
            action: this.action,
            periodType: this.periodType.value,
            periodKeyDate: this.periodKeyDate.value,
            originPeriodKeyDate: this.originPeriodKeyDate,
            originPeriodType: this.originPeriodType
        });
    }


    onDelete(): void {
        this.deletePeriod = true;
    }

    onDeleteConfirm(): void {
        this.dialogRef.close(
            {
                action: ActionType.Delete,
                originPeriodKeyDate: this.originPeriodKeyDate,
                originPeriodType: this.originPeriodType
            });
    }

    onDeleteCancel(): void {
        this.deletePeriod = false;
    }

}
